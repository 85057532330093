import React, {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import axios from "../../../../axios_tteg";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Map from "../SubComponent/Map";
import MapContainer from '../../../Maps/MapContainer';
import MapForAllCases from "../SubComponent/MapForAllCases"

const CaseManagement = ()=>{
    let container = null;
    const [cases,setCases] = useState(null);
    const [openNew,setOpenNew] = useState(false);
    const [openCase,setOpenCase] = useState(false);
    const [openMap,setOpenMap] = useState(false);
    const [openCaseMap,setOpenCaseMap] = useState(false);
    const [selelctedCaseDetail,setSelectedCaseDetail] = useState(null); 
    const [caseDetails,setCaseDetails] = useState({
        title:{
            value:null,
            valid:false
        },
        description:{
            value:null,
            valid:false
        },
        latitude:{
            value:null,
            valid:false
        },
        longitude:{
            value:null,
            valid:false
        },
        distance:{
            value:null,
            valid:false
        },
        domain:{
            value:null,
            valid:false
        },
        company:{
            value:null,
            valid:false
        },
        assetSubComponentSubCategory:{
            value:null,
            valid:false
        }
    })
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);
    const [domain,setDomain] = useState(null);
    const [company,setCompany] = useState(null);
    const [goToCaseID,setGoToCaseID] = useState(null);
    const [dataToSend,setDataToSend] = useState({
        'F-003':{
            caseID:null,
            resourceID:null,
            companyID:null,
            description:{
                role:null,
                roleName:'Planner',
                access:null,
            },
            valid:false,
        },
        'F-005':{
            caseID:null,
            resourceID:null,
            companyID:null,
            description:{
                role:null,
                roleName:'Surveyour',
                access:null,
            },
            valid:false,
        }
    });
    const [allResource,setAllResource] = useState({plannerResource:[], surveyourResource:[]}); 
    const [assetSubComponentSubCategory,setAssetSubComponentSubCategory] = useState([]);
    const [openAllCaseLocation,setOpenAllCaseLocation] = useState(false);
    const [openAwardTenderDialog,setOpenAwardTenderDialog] = useState(false);
    const [selectedCompany,setSelectedCompany] = useState(null)
    const [center,setCenter]=useState({})

    useEffect(()=>{
        getCases();
    },[])

    const getCases = ()=>{
        setLoading(true);
        axios.get('/getCases')
        .then(response=>{
            console.log(response);
            setLoading(false);
            if(response.data.cases.length > 0){
                setCases(response.data.cases)
            }
        })
        .catch(e=>console.log(e))
    }

    const getResources = ()=>{
        setLoading(true);
        axios.get('/getResource',{
            params:{
                subscriberTypeID:{
                    planner:'F-003',
                    surveyour:'F-005'
                },
            }
        })
        .then(response=>{
            setLoading(false);
            setAllResource(response.data.resources)
        })
        .catch(e=>{console.log(e);setLoading(false);})
    }

    const assetSubComponent = ()=>{
        setLoading(true);
        axios.get('/getAssetSubComponentSubCategory',{
            params:{
                subComponentCategoryID:'Material01'
            }
        })
        .then(response=>{
            console.log(response);
            setAssetSubComponentSubCategory(response.data.subComponentCategory);
        })
        .catch(e=>console.log(e))
    }

    const getDomain = ()=>{
        setLoading(true);
        axios.get('/registerPIUtility1')
        .then(response=>{
            console.log(response.data)
            setLoading(false);
            setDomain(response.data.domain)
        })
        .catch(e=>console.log(e))
    }

    const getAllCompany = (domainID) =>{
        setLoading(true);
        axios.get('/getAllVendorsFilter',{
            params:{
                resourceTypeID:'C',
                domainID:domainID
            }
        })
        .then(response=>{
            console.log(response);
            setLoading(false);
            setCompany(response.data.vendors)
        })
        .catch(e=>console.log(e))
    }

    // const getDataFromAPI = (filter) => {
    //     axios.get('/getFilteredUser',{
    //         params:{
    //             filter:filter, 
    //             type:type
    //         }
    //     }).then(response=>{
    //         setFilteredData(response.data.vendor);
    //     }).catch(e=>{
    //         console.log(e);
    //     })
    // }

    const clearValue = ()=>{
        let tempCaseDetails = {... caseDetails};
        let tempDataToSend = {... dataToSend};
        Object.keys(tempCaseDetails).map(key=>{
            tempCaseDetails[key].value = null;
            tempCaseDetails[key].valid = false;
        })
        
        Object.keys(tempDataToSend).map(key=>{
            tempDataToSend[key].caseID = null;
            tempDataToSend[key].resourceID = null;
            tempDataToSend[key].description.role = null;
            tempDataToSend[key].description.access = null;
            tempDataToSend[key].valid = false
        })
        setCaseDetails(tempCaseDetails);
        setDataToSend(tempDataToSend);
    }

    const goToCase = (item)=>{
        console.log(item);
        if(item.caseResourceAssociation.length > 0){
            let tempDataToSend = {...dataToSend};
            item.caseResourceAssociation.map(innerItem=>(
                tempDataToSend[innerItem.description.role].caseID = item.caseID,
                tempDataToSend[innerItem.description.role].resourceID = innerItem.resourceID,
                tempDataToSend[innerItem.description.role].description = innerItem.description,
                tempDataToSend[innerItem.description.role].valid = true
            ))
            setDataToSend(tempDataToSend);
        }
        setSelectedCaseDetail(item);
        setOpenCase(true);
        getResources();
        
    }

    const handleChange = ((key,value)=>{
        console.log(key,value);
        let tempCaseDetails = {... caseDetails};
        if(value){
            tempCaseDetails[key].value = value;
            tempCaseDetails[key].valid = true;
        }
        else{
            tempCaseDetails[key].value = null;
            tempCaseDetails[key].valid = false;
        }
        setCaseDetails(tempCaseDetails);
        if(key == 'domain')
            getAllCompany(value)
        
    })

    const handleSelectChange = ((caseID,subscriberTypeID,key,value,roleName)=>{
        let tempDataToSend = {... dataToSend};
        if(key === 'resource'){
            tempDataToSend[subscriberTypeID].resourceID = value;
            tempDataToSend[subscriberTypeID].caseID = caseID;
            if(tempDataToSend[subscriberTypeID].resourceID && tempDataToSend[subscriberTypeID].description.access){
                tempDataToSend[subscriberTypeID].valid = true
            }
            else{
                tempDataToSend[subscriberTypeID].valid = false
            }
        }
        else if(key === 'access'){
            tempDataToSend[subscriberTypeID].description = {
                role:subscriberTypeID,
                roleName:roleName,
                access:value
            }
            if(tempDataToSend[subscriberTypeID].resourceID && tempDataToSend[subscriberTypeID].description.access){
                tempDataToSend[subscriberTypeID].valid = true
            }
            else{
                tempDataToSend[subscriberTypeID].valid = false
            }
        }
        
        setDataToSend(tempDataToSend);
    })

    const assignResources = (subscriberTypeID,roleName)=>{
        setLoading(true);
        if(dataToSend[subscriberTypeID].valid ){
            axios.post('/caseResourceAssociation',{
                association:dataToSend[subscriberTypeID],
                log:{
                    caseID:selelctedCaseDetail.caseID,
                    description:'Planner is added',
                    status:{
                        planning:subscriberTypeID === 'F-003' ? 1:0,
                        surveyour:subscriberTypeID === 'F-005' ? 1:0
                    }
                }
            })
            .then(response=>{
                if(response.status === 200){
                    setLoading(false);
                    setOpenCase(false);
                    getCases();
                    clearValue();
                }
            })
            .catch(e=>{
                setLoading(false);
                setError(e.response.data.message);
            })
        }
        
    }

    const addNew = ()=>{
        setError(null);
        let isValid = true;
        const tempCaseDetails = {... caseDetails};
        Object.keys(tempCaseDetails).map(key=>{
            isValid = isValid && tempCaseDetails[key].valid
        })

        if(isValid){
            setLoading(true);
            axios.post('/createCase',{
                title:caseDetails.title.value,
                location:{
                    latitude:caseDetails.latitude.value,
                    longitude:caseDetails.longitude.value,
                    distance:caseDetails.distance.value,
                },
                domainID:caseDetails.domain.value,
                companyResourceID:caseDetails.company.value,
                caseDescription:caseDetails.description.value,
                assetSubComponentSubCategory:caseDetails.assetSubComponentSubCategory.value
            })
            .then((response)=>{
                console.log(response);
                if(response.status === 200){
                    setOpenNew(false);
                    clearValue();
                    getCases();
                }
                setLoading(false);
            })
            .catch(e=>{
                setLoading(false);
                setError(e.response.data.message);
            })
        }
        else{
            setError('Please fill all data marked with *')
        }
    }


    const openCaseMapHandler = (details)=>{
        console.log(details)
        setGoToCaseID(details);
        setOpenCaseMap(true);
    }

    const awardBidHandler = (details)=>{
        setOpenAwardTenderDialog(true);
        setSelectedCaseDetail(details);
        getAllCompany(details.domainID)
    }

    const assignBid = ()=>{
        setLoading(true)
        axios.post('/assignBid',{
            caseID:selelctedCaseDetail.caseID,
            resourceID:selectedCompany
        }).then(response=>{
            setLoading(false);
            alert("Bid assigned successfully")
            console.log(response)
            setOpenAwardTenderDialog(false);
            setSelectedCaseDetail(null);
            setSelectedCompany(null)
        })
    }

  
    if(cases){
        if(openCaseMap && goToCaseID){
            container = (
                <div>
                    <MapContainer 
                        caseDetails = {goToCaseID} 
                        getData = {()=>{setOpenCaseMap(false);setGoToCaseID(null)}}
                    />
                </div>
            )
        }
        else{
            container = (
                <div>
                    <div style={{display:'flex',flexWrap:'wrap', justifyContent:'space-between', fontSize:12, flexDirection:'row'}}>
                        {cases.map(item=>
                        (
                            <div style = {{margin:5, width:'49%'}}>
                                <Card sx={{ minWidth: 320,backgroundColor:'aliceblue'}}>
                                    <CardContent>
                                        <table style={{width:'-webkit-fill-available', padding:5,margin:10}}>
                                            <tr>
                                                <td>Case ID - </td>
                                                <td>{item.caseID}</td>
                                                <td>Title - </td>
                                                <td>{item.title}</td>
                                            </tr>
                                            <tr>
                                                <td>Domain - </td>
                                                <td>{item.domainDetails[0].domainDescription.domainName}</td>
                                                <td>Company - </td>
                                                <td>{item.companyDetails[0].name}</td>
                                            </tr>
                                        </table>
                                        {item.caseResourceAssociation.map(innerItem=>{
                                            console.log(innerItem);
                                            return(
                                                <table style={{width:'-webkit-fill-available', padding:10,margin:10, background:'#f5f5f5',textAlign:'left'}}>
                                                    <tr>
                                                        <td>Role - </td>
                                                        <td>{innerItem.description.roleName}</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>Name - </td>
                                                        <td>{innerItem.vendorMaster[0].name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Resource ID - </td>
                                                        <td>{innerItem.vendorMaster[0].resourceID}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Asso. Company - </td>
                                                        <td>{innerItem.associatedCompanyDetails.length > 0 && innerItem.associatedCompanyDetails[0].name}</td>
                                                    </tr>
                                                </table>
                                                
                                            )
                                            
                                        })}     
                                    </CardContent>
                                    <CardActions style={{justifyContent:'space-around'}}>
                                        <Button size="small" onClick={()=>openCaseMapHandler(item)}>Explore</Button>
                                        <Button size="small" onClick={()=>goToCase(item)}>Details</Button>
                                        <Button size="small" onClick={()=>awardBidHandler(item)}>Award Bid</Button>
                                    </CardActions>
                                </Card>
                            </div>
                        ))}
                    </div>
                    
                </div>
            )
        }
        
    }
    else{
        container = (
            <div>
                <div>
                    <p>No Cases Found !!</p>
                </div>
            </div>
        )
    }

    const getLocation2 = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCenter({ latitude, longitude });
                    console.log("Latitude:", latitude, "Longitude:", longitude);
                },
                (error) => {
                    setError(error.message);
                    console.error("Error Code:", error.code, "-", error.message);
                }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };
    useEffect(()=>{getLocation2()},[])
    const getLocation = (value)=>{
        setOpenMap(false);
        setOpenNew(true);
        let tempCaseDetails = {... caseDetails};
        tempCaseDetails['latitude'].value = value.latLng.lat;
        tempCaseDetails['latitude'].valid = true;
        tempCaseDetails['longitude'].value = value.latLng.lng;
        tempCaseDetails['longitude'].valid = true;
        tempCaseDetails['distance'].value = value.totalDistance;
        tempCaseDetails['distance'].valid = true;
        setCaseDetails(tempCaseDetails);
    }
    

    return(
        <div>
            {loading ? (
                <div>
                    <CircularProgress/>
                </div>
            ):(
                <div>
                    {!openCaseMap ? (
                        <div style={{display:'flex',justifyContent: 'space-around',alignItems: 'center'}}>
                            <div>
                                <Button variant="outlined" onClick={()=>{
                                    setOpenNew(true);
                                    getDomain();
                                    assetSubComponent();
                                }}> Add New</Button>
                            </div>
                            <div style={{width:'80%'}}>
                                <p>Case Management</p>
                            </div>
                            <div>
                                <LocationOnIcon 
                                    style = {{color:'teal', cursor:'pointer'}}
                                    onClick = {()=>setOpenAllCaseLocation(true)}
                                />
                            </div>
                            
                        </div>
                    ):null}
                    
                    {container}
                    <Dialog onClose={()=>setOpenNew(false)} open={openNew}>
                        <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                            <DialogTitle>Add New Case</DialogTitle>
                            <CloseIcon onClick = {()=>setOpenNew(false)} style = {{padding:10, cursor:'pointer'}}/>
                        </Typography>
                        
                        <Typography>
                            <FormControl style = {{width:520}}>
                                <TextField 
                                    id="outlined-basic" 
                                    label="Title *" 
                                    variant="outlined" 
                                    value = {caseDetails.title.value}
                                    onChange={(e)=>handleChange('title',e.target.value)}
                                    style = {{margin:10}}
                                    size = 'small'
                                />
                                <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Latitude in degrees *" 
                                        variant="outlined" 
                                        type='number'
                                        value = {caseDetails.latitude.value}
                                        onChange={(e)=>handleChange('latitude',parseFloat(e.target.value))}
                                        style = {{margin:10}}
                                        size = 'small'
                                    />
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Longitude in degrees *" 
                                        variant="outlined" 
                                        type = "number"
                                        value = {caseDetails.longitude.value}
                                        onChange={(e)=>handleChange('longitude',parseFloat(e.target.value))}
                                        style = {{margin:10}}
                                        size = 'small'
                                    />
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Total Distance *" 
                                        variant="outlined" 
                                        type = "number"
                                        value = {caseDetails.distance.value}
                                        onChange={(e)=>handleChange('distance',parseFloat(e.target.value))}
                                        style = {{margin:10}}
                                        size = 'small'
                                    />
                                    <LocationOnIcon 
                                        style = {{padding:5, color:'teal', cursor:'pointer'}}
                                        onClick = {()=>{setOpenMap(true);setOpenNew(false)}}
                                    />
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', margin:5}}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={caseDetails.domain.value}
                                        label="Domain *"
                                        onChange={(e)=>handleChange('domain',e.target.value)}
                                        style = {{margin:5, width:'50%'}}
                                        size = "small"
                                        >
                                            {domain && domain.map(item=>{
                                                return(
                                                    <MenuItem value = {item.domainID}>{item.domainDescription.domainName}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={caseDetails.company.value}
                                        label="Utility Company *"
                                        onChange={(e)=>handleChange('company',e.target.value)}
                                        style = {{margin:5, width:'50%'}}
                                        size = "small"
                                        >
                                            {company && company.map(item=>{
                                                return(
                                                    <MenuItem value = {item.resourceID}>{item.name}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={caseDetails.assetSubComponentSubCategory.value}
                                        label="Material *"
                                        onChange={(e)=>handleChange('assetSubComponentSubCategory',e.target.value)}
                                        // onChange={(e)=>handleSelectChange(selelctedCaseDetail.caseID,null,'material',e.target.value)}
                                        style = {{margin:'0px 10px', width:150}}
                                        size = "small"
                                        >
                                            {assetSubComponentSubCategory.map(item=>(
                                                    <MenuItem value = {item.subComponentSubCategoryID}>{item.subComponentSubCategoryName}</MenuItem>
                                                )
                                            )}
                                    </Select>
                                </div>
                                
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Description *"
                                    value = {caseDetails.description.value}
                                    multiline
                                    rows={4}
                                    onChange={(e)=>handleChange('description',e.target.value)}
                                    style = {{margin:10}}
                                />
                                <div style={{textAlign:'center', padding:10}}>
                                    <Button variant="outlined" onClick={()=>addNew()}> Submit</Button>
                                </div>
                            </FormControl>
                            <p style={{textAlign:'center',color:'red', fontSize:'small'}}>{error}</p>
                        </Typography>
                    </Dialog>
                    {
                        selelctedCaseDetail && openCase ? (
                            <Dialog onClose={()=>setOpenCase(false)} open={openCase}>
                                <div>
                                    <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                                        <DialogTitle> {selelctedCaseDetail.title}</DialogTitle>
                                        <CloseIcon onClick = {()=>setOpenCase(false)} style = {{padding:10, cursor:'pointer'}}/>
                                    </Typography>
                                    <Typography>
                                        <table style={{width:'100%', padding:10, fontSize:12, background:'aliceblue'}}>
                                            <tr>
                                                <td>CaseID</td>
                                                <td>{selelctedCaseDetail.caseID}</td>
                                            </tr>
                                            <tr>
                                                <td>Title</td>
                                                <td>{selelctedCaseDetail.title}</td>
                                            </tr>
                                            <tr>
                                                <td>Domain</td>
                                                <td>{selelctedCaseDetail.domainDetails[0].domainDescription.domainName}</td>
                                            </tr>
                                            <tr>
                                                <td>Company</td>
                                                <td>{selelctedCaseDetail.companyDetails[0].name}</td>
                                            </tr>
                                        </table>
                                    </Typography>
                                    <h3 style={{padding:10, margin:0,fontSize:14}}>Resources</h3>
                                    <Typography>
                                        <FormControl style = {{width:520}}>
                                            <Typography style = {{display:'flex', justifyContent:'space-evenly',  background:'aliceblue'}}>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    value = "Planner"
                                                    style = {{margin:5, width:150}}
                                                    disabled
                                                    size = "small"
                                                />
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={dataToSend['F-003'].resourceID}
                                                    label="Resource"
                                                    onChange={(e)=>handleSelectChange(selelctedCaseDetail.caseID,'F-003','resource',e.target.value)}
                                                    style = {{margin:5, width:150}}
                                                    size = "small"
                                                    >
                                                        {allResource.plannerResource.map(item=>(
                                                            <MenuItem value = {item.resourceID}>{item.name} - {item.resourceID}</MenuItem>
                                                        ))}
                                                </Select>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={dataToSend['F-003'].description.access}
                                                    label="Access"
                                                    onChange={(e)=>handleSelectChange(selelctedCaseDetail.caseID,'F-003','access',e.target.value,'Planner')}
                                                    style = {{margin:5, width:150}}
                                                    size = "small"
                                                    >
                                                    <MenuItem value='editor'>Editor</MenuItem>
                                                    <MenuItem value='viewer'>Viewer</MenuItem>
                                                </Select>
                                                <Button 
                                                    onClick={()=>assignResources('F-003')}
                                                >
                                                    {selelctedCaseDetail.caseResourceAssociation.length > 0 ? ("Update"):("Add")}
                                                </Button>
                                            </Typography>
                                        </FormControl>
                                        <p style={{textAlign:'center',color:'red', fontSize:'small'}}>{error}</p>
                                    </Typography>
                                    
                                </div>
                                
                            </Dialog>
                        ):null
                    }
                    {
                        openMap ? (
                            <Dialog onClose={()=>setOpenMap(false)} open={openMap} fullWidth maxWidth="xl">
                                <div>
                                    <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                                        <DialogTitle> Select Location</DialogTitle>
                                        <CloseIcon onClick = {()=>setOpenMap(false)} style = {{padding:10, cursor:'pointer'}}/>
                                    </Typography>
                                    <Typography>
                                        <Map getData = {(value)=>getLocation(value)}/>
                                    </Typography>
                                </div>
                                
                            </Dialog>
                        ):null
                    }
                    {
                        openAllCaseLocation ? (
                            <Dialog onClose={()=>setOpenAllCaseLocation(false)} open={openAllCaseLocation} fullWidth maxWidth="xl">
                                <div>
                                    <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                                        <DialogTitle> All Cases</DialogTitle>
                                        <CloseIcon onClick = {()=>setOpenAllCaseLocation(false)} style = {{padding:10, cursor:'pointer'}}/>
                                    </Typography>
                                    <Typography>
                                        <MapForAllCases
                                            cases = {cases}
                                        />
                                    </Typography>
                                </div>
                                
                            </Dialog>
                        ):null
                    }
                    {
                        selelctedCaseDetail && openAwardTenderDialog ? (
                            <Dialog onClose={()=>setOpenAwardTenderDialog(false)} open={openAwardTenderDialog}>
                                <div>
                                    <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                                        <DialogTitle> {selelctedCaseDetail.title}</DialogTitle>
                                        <CloseIcon onClick = {()=>setOpenAwardTenderDialog(false)} style = {{padding:10, cursor:'pointer'}}/>
                                    </Typography>
                                    <Typography>
                                        <table style={{width:'100%', padding:10, fontSize:12, background:'aliceblue'}}>
                                            <tr>
                                                <td>CaseID</td>
                                                <td>{selelctedCaseDetail.caseID}</td>
                                            </tr>
                                            <tr>
                                                <td>Title</td>
                                                <td>{selelctedCaseDetail.title}</td>
                                            </tr>
                                            <tr>
                                                <td>Domain</td>
                                                <td>{selelctedCaseDetail.domainDetails[0].domainDescription.domainName}</td>
                                            </tr>
                                            <tr>
                                                <td>Company</td>
                                                <td>{selelctedCaseDetail.companyDetails[0].name}</td>
                                            </tr>
                                        </table>
                                    </Typography>
                                    <h3 style={{padding:10, margin:0,fontSize:14}}>Field Construction Vendor </h3>
                                    <Typography>
                                        <FormControl style = {{width:520}}>
                                            <Typography style = {{display:'flex', justifyContent:'space-evenly',  background:'aliceblue'}}>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    value = "Vendor"
                                                    style = {{margin:5, width:150}}
                                                    disabled
                                                    size = "small"
                                                />
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedCompany}
                                                    label="Resource"
                                                    onChange={(e)=>setSelectedCompany(e.target.value)}
                                                    style = {{margin:5, width:150}}
                                                    size = "small"
                                                    >
                                                        {company && company.map(item=>(
                                                            <MenuItem value = {item.resourceID}>{item.name}</MenuItem>
                                                        ))}
                                                </Select>
                                                <Button 
                                                    onClick={()=>assignBid()}
                                                >
                                                    Select
                                                </Button>
                                            </Typography>
                                        </FormControl>
                                        <p style={{textAlign:'center',color:'red', fontSize:'small'}}>{error}</p>
                                    </Typography>
                                    
                                </div>
                                
                            </Dialog>
                        ):null
                    }
                </div>
                
            )}
            
        </div>
    )
}

export default CaseManagement