import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { SketchPicker } from "react-color";
import CloseIcon from "@mui/icons-material/Close";

//import './contextMenu.css'
import classes from "./MapCheck.module.css"

import satellite from "../../../assets/images/satellite.png";
import roadMap from "../../../assets/images/roadMap.png";
import compass from "../../../assets/images/compass.png";
import pen from "../../../assets/images/pen.png";
import hand from "../../../assets/images/hand.jpg"
import dot from "../../../assets/images/dot.png";
import line from "../../../assets/images/line.png"
import comment from "../../../assets/images/comment.png";
import location from "../../../assets/images/location.png";
import { formatRelative } from "date-fns";

import "@reach/combobox/styles.css";
import mapStyles from "./mapStyles";
import Accordian from "./UI/Accordian";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../../../axios_tteg";
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from "universal-cookie";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DescriptionIcon from '@mui/icons-material/Description';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import ImgsViewer from "react-images-viewer";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import pole from "../../../assets/pole.png"
import { CollectionsOutlined } from "@mui/icons-material";
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const cookies = new Cookies();


const libraries = ["places"];

const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};



const contextMenuStyle = {
  left : "0",
  backgroundColor : "white",
  padding: "10px 0",
  zIndex: "99",
  width: "200px",
  top: "calc(100% + 10px)",
  borderRadius: "4px",
  boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)"
}



const contextMenuItemStyle = {
  fontSize: "14px",
  padding: "10px 15px",
  cursor: "pointer",
  transition: "0.2s",
  marginBottom: "2px",
  fontWeight: "500",
  display: "flex",
  columnGap : "10px",
  backgroundColor:"#fff",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#cccccc"
  }
}

const mapViewStyle = {
  width:50,
  margin:10,
  boxShadow: '0 2px 3px #ccc',
  border: '1px solid #eee',
  cursor:"pointer"
}

// const center = {
//   lat: 28.70406,
//   lng: 77.102493,
// };

export default function MapCheck(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC_kRxUGZMpYA361uWGeGohZW5PYqCoj4k",
    libraries,
  });

  const [center,setCenter] = useState(null);
  const [layers,setLayers] = React.useState([[
    {
      markers : [],
      dataCount : {}
    }
  ]]);
  const [inputLat,setInputLat] = React.useState(null);
  const [inputLng,setInputLng] = React.useState(null);
  const [latLng,setLatLng] = React.useState({}); // to store the latitude and longitude when user clicks 
  const [manholePopBox, setManholePopBox] = React.useState(false);
  const [lineActive,setLineActive] = React.useState(false);
  // const [markers,setMarkers] = React.useState([]);
  const [edit,setEdit] = React.useState(false);
  const [lineColor,setLineColor] = React.useState("0000ff")
  const [polyLineActive,setPolyLineActive] = React.useState(false); 
  // const [dataCount,setDataCount] = React.useState({
  //   WayPoint:0,
  //   Manhole :0
  // })
  const [selected, setSelected] = React.useState({
    marker: null,
    index: null,
  });
  const [selRouteIndex,setSelectedRoute] = React.useState(0);
  const [selLayerIndex,setSelectedLayer] = React.useState(0);
  const [inputLayers,setInputLayers] = React.useState([
    ])
  const [inputResourceID,setInputResourceID] = useState(cookies.get("userData").resourceID);
  const [loading,setLoading] = React.useState(false);
  const [error,setError] = React.useState(false);
  

  const [editableLayer,setEditableLayer] = useState(null)
  const [saveMap,setSaveMap] = useState(false);


  // For checkbox selection of routes
  

  const [editLayerIndex,setEditLayerIndex] =  useState(0);
  const [assetsType,setAssetsType] = useState([]);
  const [pipeTypes,setPipeTypes] = useState([]);
  const [showComment,setShowComment] = useState(false);
  const [showPipeConfig,setShowPipeConfig] = useState(false);
  const [showDetailedBOQ,setShowDetailedBOQ] = useState(false);
  const [openZoomImage,setOpenZoomImage] = useState({status:false,uri:null});
  const [layerResourceID,setLayerResourceID] = useState([]);
  const [materialItem,setMaterialItem] = useState([]);
  const [serviceItem,setServiceItem] = useState([]);
  const [materialofBOQ,setMaterialofBOQ] = useState([{
    item:null,
    unit:null,
    quantity:null
  }])
  const [servicesofBOQ,setServicesofBOQ] = useState([{
    item:null,
    unit:null,
    quantity:null
  }])

  const [manholeHover,setManholeHover] = useState(null);
  const innerWidth = window.innerWidth;

  useEffect(()=>{
    setMaterialofBOQ([{
      item:null,
      unit:null,
      quantity:null,
      catalogue:null
    }])
    setServicesofBOQ([{
      item:null,
      unit:null,
      quantity:null,
      catalogue:null
    }])
    getSavedMap();
    getAssetsType();
    getMaterialItem();
    getServiceItem();
    setCenter({
      lat: props.caseDetails.caseMaster[0].location.latitude,
      lng: props.caseDetails.caseMaster[0].location.longitude,
    })
    
  },[props.caseDetails]);

  const checkAccess = ()=>{
    let accessGranted = false;
    props.caseDetails.caseResourceAssociation.map(item=>{
      if(item.resourceID == inputResourceID || cookies.get('userData').resourceTypeID === 'T'){
        accessGranted = true;
      }
    })
    return accessGranted;
  }

  const getAccesswithRole = (resourceID)=>{
    let dataToSend = {
        role:null,
        found:false,
        access:null,
        companyID:null
    }
    if (props.caseDetails.caseResourceAssociation.length > 0){
        props.caseDetails.caseResourceAssociation.map(item=>{
            if(item.resourceID === resourceID){
                dataToSend = {
                    role : item.description.role,
                    access:item.description.access,
                    found : true,
                    companyID:item.companyID
                }
            }
        })
    }
    return dataToSend
  }

  const getSavedMap = ()=>{
    let tempInputLayer = []
    axios.get('/getMapAuditFinal',{
      params:{
        caseID:props.caseDetails.caseID
      }
    })
    .then(response=>{
      let tempLayerResourceID = [];
      if(response.data.store.length > 0){
        response.data.store.map(item=>{
          tempInputLayer.push(item);
        })
        if(tempInputLayer.length > 0){
          let tempLayers = [];
          let tempEditableIndex = null;
          
          tempInputLayer.map((item,index)=>{
            if(item.resourceID === inputResourceID && 
              getAccesswithRole(item.resourceID).found && 
              getAccesswithRole(item.resourceID).access == 'editor'){
              tempEditableIndex = index;
              setEditLayerIndex(index);
            } 

            tempLayerResourceID.push({
                layerID:index,
                resourceID:item.resourceID,
                role:getAccesswithRole(item.resourceID).role,
                companyID:getAccesswithRole(item.resourceID).companyID
            })
            
            if(!item.data[0].dataCount){
                item.data[0]['dataCount'] ={}
            }
            tempLayers.push(item.data);
          })
          if(getAccesswithRole(inputResourceID).access === 'editor' && tempEditableIndex === null){
            tempLayers.push([{
                markers : [],
                dataCount : {}
            }]);
            // let tempLayerResourceID = {...layerResourceID};
            tempLayerResourceID.push({
                layerID:tempInputLayer.length,
                resourceID:inputResourceID,
                role:getAccesswithRole(inputResourceID).role,
                companyID:getAccesswithRole(inputResourceID).companyID
            })
            // setLayerResourceID(tempLayerResourceID);
            setEditLayerIndex(tempInputLayer.length)
          }
          setLayers(tempLayers);
          setLayerResourceID(tempLayerResourceID);
          if(tempInputLayer[0].materialofBOQ) 
            setMaterialofBOQ(tempInputLayer[0].materialofBOQ);
          if(tempInputLayer[0].servicesofBOQ) 
            setServicesofBOQ(tempInputLayer[0].servicesofBOQ)
        }
        else{
          if(getAccesswithRole(inputResourceID).access === 'editor'){
            // let tempLayerResourceID = {...layerResourceID};
            tempLayerResourceID.push({
                layerID:0,
                resourceID:inputResourceID,
                role:getAccesswithRole(inputResourceID).role,
                companyID:getAccesswithRole(inputResourceID).companyID
            })
            setLayerResourceID(tempLayerResourceID);
            setEditLayerIndex(0);
            
          }
        }
      }
      else if(getAccesswithRole(inputResourceID).access === 'editor'){
        tempLayerResourceID.push({
            layerID:0,
            resourceID:inputResourceID,
            role:getAccesswithRole(inputResourceID).role,
            companyID:getAccesswithRole(inputResourceID).companyID
        })
        setLayerResourceID(tempLayerResourceID);
        setInputLayers(tempInputLayer);
        
        // setGoToLocation(currentLocation); // change here
        // Add starting Location of map and give Different markers for starting point
      }
        
    })
    .catch(e=>console.log(e))
  }

 const getMaterialItem = ()=>{
  axios.get('/getAssetSubComponent',{
    params:{
      subComponentSubCategoryID:props.caseDetails.caseMaster[0].assetSubComponentSubCategory,
      subComponentCategoryID:'Material01'
    }
  })
  .then(response=>{
    setMaterialItem(response.data.assetSubComponent);
  })
  .catch(e=>console.log(e))
 }

 const getServiceItem = ()=>{
  axios.get('/getAssetSubComponent',{
    params:{
      subComponentSubCategoryID:props.caseDetails.caseMaster[0].assetSubComponentSubCategory,
      subComponentCategoryID:'Services01'
    }
  })
  .then(response=>{
    setServiceItem(response.data.assetSubComponent);
  })
  .catch(e=>console.log(e))
 }


  const getAssetsType = ()=>{
    axios.get('/getAssetType')
    .then(response=>{
      if(response.data.assets.length > 0){

          setAssetsType(response.data.assets)
      }     
    })
    .catch(e=>console.log(e))
  }

  const getAssetComponent = (assetID)=>{
    axios.get('/getAssetComponent',{
        params:{
            assetID:assetID
        }
    })
    .then(response=>{
        if(response.data.assets.length > 0){

            setPipeTypes(response.data.assets)
        }
            
    })
    .catch(e=>console.log(e))
  }

  const handleChangeMaterial = (index,key,value)=>{
    setError(null)
    let tempMaterialofBOQ = [... materialofBOQ];
    let found = false;
    tempMaterialofBOQ.map(item=>{
      if(key === 'item' && item.item === JSON.parse(value).item)
        found = true;
    })
    if(found){
      setError('You cannot add same item twice');
    }
    else{
      if(key === 'item'){
        tempMaterialofBOQ[index]['unit'] = JSON.parse(value).unit;
        tempMaterialofBOQ[index][key] = JSON.parse(value).item;
        tempMaterialofBOQ[index]['catalogue'] = JSON.parse(value).catalogue;
      }
      else
        tempMaterialofBOQ[index][key] = value;
      setMaterialofBOQ(tempMaterialofBOQ);
    }
    
  }

  const handleChangeServices = (index,key,value)=>{
    setError(null);
    let tempServicesofBOQ = [... servicesofBOQ];
    let found = false;
    tempServicesofBOQ.map(item=>{
      if(key === 'item' && item.item === JSON.parse(value).item)
        found = true;
    })
    if(found){
      setError('You cannot add same item twice');
    }
    else{
      if(key === 'item'){
        tempServicesofBOQ[index]['unit'] = JSON.parse(value).unit;
        tempServicesofBOQ[index][key] = JSON.parse(value).item;
        tempServicesofBOQ[index]['catalogue'] = JSON.parse(value).catalogue;
      }
      else
        tempServicesofBOQ[index][key] = value;
      setServicesofBOQ(tempServicesofBOQ);
    }
    
  }


  useEffect(()=>{
    
    //if(editableLayer === selLayerIndex)
    setEdit(props.edit);
    setInputLat(props.panTo.lat);
    setInputLat(props.panTo.lng);
    setSelectedRoute(props.selectedRouteIndex);
    setSelectedLayer(props.selectedLayerIndex);
    let tempMarker = selectRouteDetails();
    props.getMarkers(tempMarker)
    if(props.panTo.lat && props.panTo.lng)
      panTo({lat : parseFloat(props.panTo.lat),lng : parseFloat(props.panTo.lng)})
    if(props.add){
      addTo(props.add)
    }

    setSaveMap(props.saveMap);
    if(props.saveMap){
      saveMapHandler();
      props.returnSave(false);
    }

    if(props.delRouteIndex !== null){
      deleteTo('delroutes',props.delRouteIndex)
    }
  },[props.delRouteIndex,props.saveMap,props.add,props.panTo,props.selectedLayerIndex,props.selectedRouteIndex,props.edit]);


  const deleteTo = (key,index)=>{
    if(key === 'delroutes'){
        let tempLayers = [... layers];
        if(tempLayers[editLayerIndex].length > 1){
            tempLayers[editLayerIndex].splice(index,1);
        }
        else if(tempLayers[editLayerIndex].length === 1){
          tempLayers[editLayerIndex] = [
            {
              markers : [],
              dataCount : {}
            }
          ]
        }
        setLayers(tempLayers);
        props.returnDelete();
    }
    
  }

  useEffect(()=>{
    let tempMarker = selectRouteDetails();
    props.getMarkers(tempMarker)
  },[props.viewLayerIndex,props.viewRouteIndex, layers])

  const selectRouteDetails = ()=>{
    const tempLayers = [...layers];
    let tempMarkerGrp = tempLayers[props.viewLayerIndex]
    const tempMarkers=  [
      // ...markers,
      ...tempMarkerGrp[props.viewRouteIndex].markers
    ]
    const dataCount = {
        ... tempMarkerGrp[props.viewRouteIndex].dataCount
    }
    
    return(tempMarkers);
  }

  const addTo = (data)=>{
    if(data.type === 'addlayers'){
      const tempLayers = [...layers];
      tempLayers.push([
        {
          markers : [],
          dataCount : {}
        }
      ]);
      setLayers(tempLayers);
      setSelectedLayer(tempLayers.length-1);
      setSelectedRoute(0)
    }
    if(data.type === 'addroutes'){
      const tempLayers = [...layers];
      const tempMarkerGrp = tempLayers[props.editLayerIndex];
      tempMarkerGrp.push({
        markers : [],
        dataCount : {}
      });
      tempLayers[props.editLayerIndex] = [...tempMarkerGrp]
      // setMarkerGrp(tempMarkerGrp);
      setLayers(tempLayers);
      setLineActive(false);
      setManholePopBox(false);
      setSelectedRoute(tempMarkerGrp.length-1)
    }
  }
  // sending the structure of layers

  useEffect(()=>{
    createLayerStructure();
    },[layers,editableLayer,props.selIndex,props.viewLayerIndex,props.viewRouteIndex,props.editLayerIndex,props.editRouteIndex,props.caseDetails,layerResourceID])
  

  const createLayerStructure = ()=>{
    let layersStructure = [];
    if(layers.length > 0){
      layers.map((outerItem,outerIndex)=>{
          if((getSelected('layerIndex',outerIndex,null)).found){
              if(outerIndex === props.viewLayerIndex){
                  if(outerIndex === props.editLayerIndex)
                      layersStructure[outerIndex] = {name:'Layer',index:outerIndex,routes:[],selected:true,edit:true, view:true}
                  else
                      layersStructure[outerIndex] = {name:'Layer',index:outerIndex,routes:[],selected:true,edit:false,view:true}
              }
              else{
                  if(outerIndex === props.editLayerIndex)
                      layersStructure[outerIndex] = {name:'Layer',index:outerIndex,routes:[],selected:true,edit:true, view:false}
                  else
                      layersStructure[outerIndex] = {name:'Layer',index:outerIndex,routes:[],selected:true,edit:false,view:false}
              }
                  
          }
          else
              layersStructure[outerIndex] = {name:'Layer',index:outerIndex,routes:[],selected:false, edit:false,view:false}
          
          outerItem.map(async (innerItem,innerIndex)=>{
              if((getSelected('routeIndex',outerIndex,innerIndex)).found ){

                  if(innerIndex === props.viewRouteIndex && outerIndex === props.viewLayerIndex){
                      if(innerIndex === props.editRouteIndex && outerIndex === props.editLayerIndex)
                          layersStructure[outerIndex]['routes'].push({name:'Route',index:innerIndex,selected:true,edit:true,view:true,color:innerItem.color});
                      else
                          layersStructure[outerIndex]['routes'].push({name:'Route',index:innerIndex,selected:true,edit:false,view:true,color:innerItem.color});
                  }
                  else{
                      if(innerIndex === props.editRouteIndex && outerIndex === props.editLayerIndex)
                          layersStructure[outerIndex]['routes'].push({name:'Route',index:innerIndex,selected:true,edit:true,view:false,color:innerItem.color});
                      else
                          layersStructure[outerIndex]['routes'].push({name:'Route',index:innerIndex,selected:true,edit:false,view:false,color:innerItem.color});
                  }
              }
              else
                  layersStructure[outerIndex]['routes'].push({name:'Route',index:innerIndex,selected:false,edit:false,view:false,color:innerItem.color});
          })
        })
        // setLayerStructure(layersStructure);    
        
    }
    props.getLayers(layersStructure,editLayerIndex,layerResourceID); 
  }

  const onMapRightClick = React.useCallback((e) => {
    setLatLng({
        lat:e.latLng.lat(),
        lng:e.latLng.lng(),
        time:new Date()
    })
  }, []);


  const addWayPoint = (e) => {
    const tempLayers = [...layers];
    let tempMarkerGrp = tempLayers[props.editLayerIndex]
    let wayPointCount = tempMarkerGrp[props.editRouteIndex].dataCount.WayPoint
    if(wayPointCount == undefined) wayPointCount = 0
    let tempMarkers = [];
    if(props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Aerial01'){
      tempMarkers=  [
        // ...markers,
        ...tempMarkerGrp[props.editRouteIndex].markers,
        {
          type : "Pole",

          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          time: new Date(),
          count : wayPointCount+1
        },
      ]
    }
    else if(props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Underground01'){
      tempMarkers=  [
        // ...markers,
        ...tempMarkerGrp[props.editRouteIndex].markers,
        {
          type : "WayPoint",

          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          time: new Date(),
          count : wayPointCount+1
        },
      ]
    } 
    const dataCount = {
        ... tempMarkerGrp[props.editRouteIndex].dataCount, 
        WayPoint : wayPointCount+1
    }
    // setMarkers(tempMarkers);
    tempMarkerGrp[props.editRouteIndex].markers =tempMarkers;
    tempMarkerGrp[props.editRouteIndex].dataCount =dataCount;
    
    // setDataCount({... dataCount, WayPoint : dataCount["WayPoint"]+1}); //not needed
    // setMarkerGrp(tempMarkerGrp);
    tempLayers[props.editLayerIndex] = tempMarkerGrp;
    // if (!mapRef.current) return;

    // const newPos = mapRef.current.getCenter().toJSON();
    // center = {
    //   lat: newPos.lat,
    //   lng: newPos.lng,
    // };
    setLayers(tempLayers);
    props.getMarkers(tempMarkers);
  }
  const addMarker = (e) => {
    let  markerType = e.target.attributes.getNamedItem("data-marker-type")
    // if he clicks on context Menu but not the menu item
    if(markerType) markerType = markerType.value; 
    else return;
    if(markerType==="WayPoint"){
          setManholePopBox(true);
          return;
    }
    let prevManhole = null,i;
    // assigning the previous.C  to currentManhole.A
    const tempLayers = [...layers];
    const tempMarkerGrp = tempLayers[props.editLayerIndex]
    const tempMarkers = tempMarkerGrp[props.editRouteIndex].markers
      if(markerType==="Manhole"){
        for(let i=tempMarkers.length-1;i>=0;i--){
          if(tempMarkers[i].type === "Manhole"){
              prevManhole = tempMarkers[i];
              break;
          }
        }       
      }
    // we need to insert correctly into main markers array
    let x = latLng.lat,y = latLng.lng;
    for(i=0;i<tempMarkers.length-1;i++){
        let x1 = tempMarkers[i].lat,y1 = tempMarkers[i].lng,x2 = tempMarkers[i+1].lat,y2 = tempMarkers[i+1].lng
        // line equation
        // y-y1 = (y2-y1)/(x2-x1) * (x-x1)
        // (y-y1)*(x2-x1) = (y2-y1)*(x-x1);
        if(Math.abs(((y-y1)*(x2-x1)).toFixed(12)-((y2-y1)*(x-x1)).toFixed(12))<6e-9){
        }
        else  continue;
        let maxLat = Math.max(x1,x2),minLat = Math.min(x1,x2),maxLng = Math.max(y1,y2),minLng = Math.min(y1,y2);
        if((maxLat-x)>0 && (x-minLat)>0 && (maxLng-y)>0 &&  (y-minLng)>0 ){
          break;
        }
    }
    let manholeCount= tempMarkerGrp[props.editRouteIndex].dataCount.Manhole
    if(manholeCount == undefined) manholeCount = 0
    const dataCount = {
      ... tempMarkerGrp[props.editRouteIndex].dataCount, 
      Manhole : manholeCount+1
    } 
    tempMarkers.splice(i+1, 0,{
          type : markerType,
          lat: latLng.lat,
          lng: latLng.lng,
          time: new Date(),
          count : manholeCount+1,
          ...(markerType ==="Manhole") && 
          {pipeContainers : {
            "A" : prevManhole ? [...prevManhole.pipeContainers.C] : [],
            "B" : [],
            "C" : [],
            "D" : []
          }}
    });

    tempMarkerGrp[props.editRouteIndex].markers = [...tempMarkers];
    tempMarkerGrp[props.editRouteIndex].dataCount = dataCount;
    tempLayers[props.editLayerIndex] = [...tempMarkerGrp]
    // setMarkerGrp(tempMarkerGrp);
    setLayers(tempLayers);
    // setDataCount({... dataCount, Manhole : dataCount["Manhole"]+1});
    // setMarkers(tempMarkers);
  // setMarkers(tempMarkers);
    props.getMarkers(tempMarkers);
    // const tempMarkers = [
    //   ...markers,
    //   {
    //     type : markerType,
    //     lat: latLng.lat,
    //     lng: latLng.lng,
    //     time: new Date(),
    //     ...(markerType ==="Manhole") && 
    //     {pipeContainers : {
    //       "A" : prevManhole ? [...prevManhole.pipeContainers.C] : [],
    //       "B" : [],
    //       "C" : [],
    //       "D" : []
    //     }}
    //   }
    // ]
    // setMarkers(tempMarkers);
    // props.getMarkers(tempMarkers);
  }
  
  const tableRef = useRef(null);
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const adjustMarkers = (markerIndex,tempMarkers) => {
      let markerPos = tempMarkers[markerIndex].count,markerType =tempMarkers[markerIndex].type;
      for(let i=0;i<tempMarkers.length;i++){
          if((markerType === tempMarkers[i].type) && (tempMarkers[i].count>markerPos))
                tempMarkers[i].count-=1;
      }
      // setMarkerGrp(markerGrp);
      // setDataCount({... dataCount,[markerType] : dataCount[[markerType]]-1})
      return tempMarkers
  }
  // deleting the marker
  const deleteMarker = (delLayerIndex,delRouteIndex,markerIndex) => {
      if(delLayerIndex!==props.editLayerIndex || delRouteIndex !== props.editRouteIndex){
        alert('You have no right to delete.')
        return;
      }
      const tempLayers = [...layers];
      const tempMarkerGrp = tempLayers[delLayerIndex]
      let tempMarkers = tempMarkerGrp[delRouteIndex].markers,markerType =tempMarkers[markerIndex].type;;
      // if the type is manhole we should make sure the previous and next manhole must be in sync
      tempMarkers= adjustMarkers(markerIndex,tempMarkers);
      if(tempMarkers[markerIndex].type==="Manhole")
        tempMarkers = deleteManhole(markerIndex,tempMarkers);
      tempMarkers.splice(markerIndex,1);
      const dataCount = {
        ... tempMarkerGrp[delRouteIndex].dataCount, 
        [markerType] : tempMarkerGrp[delRouteIndex].dataCount[[markerType]]-1
      } 
      // setMarkers(tempMarkers);
      tempMarkerGrp[delRouteIndex].markers = [...tempMarkers];
      tempMarkerGrp[delRouteIndex].dataCount = dataCount;
      tempLayers[delLayerIndex] = [...tempMarkerGrp];
      // setMarkerGrp(tempMarkerGrp);
      setLayers(tempLayers)
      props.getMarkers(tempMarkers);
      setSelected({
        routeIndex : null,
        layerIndex:null,
        marker: null,
        index: null,
      });
  }

  const deleteManhole = (markerIndex,tempMarkers) => {
    const currManhole = tempMarkers[markerIndex];
    if(currManhole.pipeContainers.A ==currManhole.pipeContainers.C)
      return tempMarkers;
    let prevManhole=null;
    for(let i=markerIndex-1;i>=0;i--){
      if(tempMarkers[i].type === "Manhole"){
        prevManhole = tempMarkers[i];
        break;
      }
    } 
    if(!prevManhole) return tempMarkers;
    prevManhole.pipeContainers.C = [...currManhole.pipeContainers.C];
    return tempMarkers;
  };
  
  const panTo = React.useCallback(({ lat, lng }) => {
    try{
      mapRef.current.panTo({ lat, lng });
    }
    catch(err){
      alert(err);
    }
    mapRef.current.setZoom(16);
  }, []);

  // handling the update of manhole
  const handleAccordianData = (pipeContainer,routeIndex) => {
    if(routeIndex!==props.editRouteIndex){
      alert('You have no right to edit');
      return;
    }
      
    const tempLayers = [...layers];
    const tempMarkerGrp = tempLayers[props.editLayerIndex]
    const tempMarkers = tempMarkerGrp[props.editRouteIndex].markers
    const markerIndex = selected.index-1,currManhole = tempMarkers[markerIndex];
    currManhole.pipeContainers = pipeContainer;
    let prevManhole=null,nextManhole=null;
    for(let i=markerIndex-1;i>=0;i--){
      if(tempMarkers[i].type === "Manhole"){
        prevManhole = tempMarkers[i];
        break;
      }
    } 
    if(prevManhole) prevManhole.pipeContainers.C = currManhole.pipeContainers.A;
    for(let i=markerIndex+1;i<tempMarkers.length;i++){
      if(tempMarkers[i].type === "Manhole"){
        nextManhole = tempMarkers[i];
        break;
      }
    }     
    if(nextManhole) nextManhole.pipeContainers.A = currManhole.pipeContainers.C;   
    tempMarkerGrp[props.editRouteIndex].markers = tempMarkers;
    // setMarkers(tempMarkers);
    // setMarkerGrp(tempMarkerGrp);
    props.getMarkers(tempMarkers)
    tempLayers[props.editLayerIndex] = [...tempMarkerGrp]
    setLayers(tempLayers)
    setSelected({
      marker: null,
      index: null,
      routeIndex:null,
      layerIndex:null
    })
  }

  const onMarkerDragEnd = (coord,{index}) => {
    const tempLayers = [...layers];
    const tempMarkerGrp = tempLayers[props.editLayerIndex]
    const tempMarkers = tempMarkerGrp[props.editRouteIndex].markers
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    tempMarkers[index] = { ...tempMarkers[index],lat, lng };
    tempMarkerGrp[props.editRouteIndex].markers = [...tempMarkers];
    tempLayers[props.editLayerIndex] = [...tempMarkerGrp]
    // setMarkers(tempMarkers);
    // setMarkerGrp(tempMarkerGrp);
    setLayers(tempLayers)
    // setMarkers(tempMarkers);
    props.getMarkers(tempMarkers);
  };
  const addRoute = () => {

    // if the route is empty i need to ignore
    const tempLayers = [...layers];
    const tempMarkerGrp = tempLayers[props.editLayerIndex];
    if(tempMarkerGrp[props.editRouteIndex].markers.length)
      return;
    tempMarkerGrp.push({
      markers : [],
      dataCount : {}
    });
    tempLayers[props.editLayerIndex] = [...tempMarkerGrp]
    // setMarkerGrp(tempMarkerGrp);
    setLayers(tempLayers);
    setLineActive(false);
    setManholePopBox(false);
    setSelectedRoute(tempMarkerGrp.length-1)
    // setMarkers([]);
  }


  const saveMapHandler = ()=>{
    if(props.overallEdit && checkAccess()){
      setLoading(true);
      axios.post('/mapAuditLog',{
        caseID:props.caseDetails.caseID,
        resourceID:cookies.get('userData').resourceID,
        data:layers[props.editLayerIndex],
        materialofBOQ:materialofBOQ,
        servicesofBOQ:servicesofBOQ
      }).
      then(response=>{
        console.log(response)
      })
      .catch(e=>{
        console.log(e)
      })
      axios.post('/mapAuditFinal',{
        caseID:props.caseDetails.caseID,
        resourceID:props.caseDetails.resourceID ? props.caseDetails.resourceID : props.caseDetails.caseResourceAssociation[editLayerIndex].resourceID,
        data:layers[props.editLayerIndex],
        materialofBOQ:materialofBOQ,
        servicesofBOQ:servicesofBOQ
      })
      .then(response=>{
        setLoading(false)
        if(response.status === 200){
          alert('saved Successfully');
          getSavedMap();
        }
      })
      .catch(e=>{
        setLoading(false)
        alert('error Found')
        console.log(e)
      })
    }
    else{
      alert('You don`t have a right to save')
    }
  }

  const getSelected = (key,layerIndex,routeIndex)=>{
    let found = false;
    let foundIndex = null;
    if(key === 'layerIndex'){
        props.selIndex.map((item,index)=>{
            if(item.layer === layerIndex){
                found = true;
                foundIndex={layerIndex:index,routeIndex:null};
            }
        })
    }
    else if(key === 'routeIndex'){
        props.selIndex.map((item,index)=>{
            let tempRouteIndex = [];
            if(item.layer === layerIndex){
                item.route.map((innerItem,innerIndex)=>{
                    if(innerItem === routeIndex){
                        tempRouteIndex.push(innerIndex);
                        found = true;
                        foundIndex = {layerIndex:index,routeIndex:tempRouteIndex};
                    }
                })
                
            }       
        })
    }
    return ({found:found,foundIndex:foundIndex});
  }

  const distBtwPoints = (coords1, coords2) => {
    const R = 6371e3; // metres
    const φ1 = parseFloat(coords1.lat) * Math.PI / 180; // φ, λ in radians
    const φ2 = parseFloat(coords2.lat) * Math.PI / 180;
    const Δφ = (parseFloat(coords2.lat) - parseFloat(coords1.lat)) * Math.PI / 180;
    const Δλ = (parseFloat(coords2.lng) - parseFloat(coords1.lng)) * Math.PI / 180;
  
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
       Math.cos(φ1) * Math.cos(φ2) *
       Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    return R * c; // in metres
  }

  const getDetailedBOQ = ()=>{
    let pipeDesc = {};
    let countDesc = {};
    let MHContainer = [];
    let PoleContainer = [];
    // layers[0].map((layer,layerIndex)=>{
    layers[0].map((route,routeIndex)=>{
      route.markers.map((marker,markerIndex)=>{
        if(marker.type === 'Manhole'){
          if(countDesc[marker.type]){
            countDesc[marker.type] = countDesc[marker.type]+1;
          }
          else{
            countDesc[marker.type] = 1;
          }
          MHContainer.push(marker);
        }
        else if(marker.type === 'Pole'){
          if(countDesc[marker.type]){
            countDesc[marker.type] = countDesc[marker.type]+1;
          }
          else{
            countDesc[marker.type] = 1;
          }
          PoleContainer.push(marker);
        }
      })
    })
    if(MHContainer.length > 0){
      for(let i = 0;i < MHContainer.length; i = i+1){
        if(i+1 < MHContainer.length){
          MHContainer[i].pipeContainers.C.map((innerItem,innerIndex)=>{
            if(pipeDesc[innerItem.PipeType]){
              pipeDesc[innerItem.PipeType].push({
                quantity:innerItem.Quantity,
                length : distBtwPoints(
                  {
                    lat:MHContainer[i].lat,
                    lng:MHContainer[i].lng
                  },{
                    lat:MHContainer[i+1].lat,
                    lng:MHContainer[i+1].lng
                  })
              }) 
            }
            else{
              pipeDesc[innerItem.PipeType] = [{
                quantity:innerItem.Quantity,
                length : distBtwPoints(
                  {
                    lat:MHContainer[i].lat,
                    lng:MHContainer[i].lng
                  },{
                    lat:MHContainer[i+1].lat,
                    lng:MHContainer[i+1].lng
                  }
                )
              }]
            }
            
          })
        }
      }
    }
      
    return({
      pipeDesc:pipeDesc,
      countDesc:countDesc
    })
  }

  const getMaterialName = (assetSubComponentID)=>{
    let assetSubComponentName = null;
    materialItem.map(item=>{
      if(item.assetSubComponentID === assetSubComponentID)
        assetSubComponentName = item.assetSubComponentName
    })
    return (assetSubComponentName)
  } 

  const getServiceName = (assetSubComponentID)=>{
    let assetSubComponentName = null;
    serviceItem.map(item=>{
      if(item.assetSubComponentID === assetSubComponentID)
        assetSubComponentName = item.assetSubComponentName
    })
    return (assetSubComponentName)
  }

  const downloadToExcel = ()=>{
    const dataSet = [];
    Object.keys(getDetailedBOQ().countDesc).map(key=>{
      dataSet.push({
        Item:key,
        unit:'Nos.',
        quantity:getDetailedBOQ().countDesc[key]

      })
    })
    Object.keys(getDetailedBOQ().pipeDesc).map(key=>{
      dataSet.push({
        Item:key,
        unit:'meters',
        quantity:displaySumForBOQ(getDetailedBOQ().pipeDesc[key])
      })
    })
    materialofBOQ.map((item,index)=>{
      dataSet.push({
        Item:getMaterialName(item.item),
        unit:item.unit,
        quantity:item.quantity
      })
    })

    servicesofBOQ.map((item,index)=>{
      dataSet.push({
        Item:getServiceName(item.item),
        unit:item.unit,
        quantity:item.quantity
      })
    })

    // let header = ["Item", "Unit", "Quantity","Length"];
    let header = ["Item", "Unit", "Quantity/Length"];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, dataSet, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {  type: 'array', cellStyles:true });
    const finalData = new Blob([excelBuffer]);
    FileSaver.saveAs(finalData, "BOQ.xlsx");
  }

  const displaySumForBOQ = (majorItem)=>{
    let sum = 0;
    parseFloat(majorItem.map(item=>{
      sum += item.quantity * item.length
    }))
    return(sum.toFixed(2))
  }
  
  let container = null;
  if(saveMap){
    container = (
      <div>
        <Dialog
          open={saveMap}
          onClose={setSaveMap(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to save the map?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setSaveMap(false)}>Disagree</Button>
            <Button onClick={saveMapHandler} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div>
      <Locate panTo={panTo} />
      {/* <Search panTo={panTo} /> */}
      <ContextMenuTrigger id="contextmenu">
      {loading? (<div style={{position :'absolute', top:'50%', left:'50%'}}><CircularProgress/></div>):(
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          mapTypeId = {props.mapView}
          tilt={0}
          zoom={40}
          center={center} 
          onClick={edit && lineActive && checkAccess() && props.overallEdit && props.mapView==='satellite' ? addWayPoint : null}
          options={options}
          onLoad={onMapLoad} // when map loads it should be in the browserLocation
          clickableIcons = {false}
          onRightClick={(edit && checkAccess() && props.overallEdit) ? ((e)=>{
            setPolyLineActive(false);
            onMapRightClick(e);
          }): null}
          showsBuildings={false}
        >
          <Marker
            label="Initial Location"
             position={{
              lat: props.caseDetails.caseMaster[0].location.latitude,
              lng: props.caseDetails.caseMaster[0].location.longitude,
            }}
            pin
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
              scaledSize: new window.google.maps.Size(30, 30),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              labelOrigin: new window.google.maps.Point(15, -10)
            }}
          />
          {
            layers.map((item,layerIndex)=>{
              if(getSelected('layerIndex',layerIndex).found){
                if(layerResourceID.length > 0 && layerResourceID[layerIndex].role === 'F-005'){
                  if(layerResourceID[layerIndex].resourceID === inputResourceID || 
                    layerResourceID[layerIndex].companyID === inputResourceID || 
                    cookies.get('userData').resourceTypeID === 'T'||
                    cookies.get('userData').subscriberTypeID === "F-003"){
                    return(
                      item.map(({markers},routeIndex) => {
                        if(getSelected('routeIndex',layerIndex,routeIndex).found){
                          return(
                            <>
                            {
                              markers.map((marker,index) => { 
                                return (
                                  <Marker
                                    data-count = {marker.count}
                                    draggable = {routeIndex === props.editRouteIndex && edit && props.overallEdit && checkAccess() }
                                    label = {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                    key={`${marker.time.toString()}_${index}`}
                                    position={{ lat: marker.lat, lng: marker.lng }}
                                    onClick={(e) => {
                                      setSelected({
                                        marker,
                                        layerIndex,
                                        routeIndex,
                                        index: index+1,
                                      });
                                      if(innerWidth <= 768 && marker.type === "Manhole"){
                                        setManholeHover({
                                          position:{
                                            lat: marker.lat, 
                                            lng: marker.lng 
                                          },
                                          markerIndex : marker.count, 
                                          routeIndex : routeIndex,
                                          accordianData :  marker.pipeContainers,
                                          showPipeSide : {
                                            A:false,
                                            B:false,
                                            C:false,
                                            D:false
                                          } 
                                        })
                                      }
                                    }}

                                    onDragEnd = {(e)=>{
                                      onMarkerDragEnd(e,{marker,index:index,routeIndex});
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(marker.type === "Manhole"){
                                        props.getManholeHover({
                                          position:{
                                            lat: marker.lat, 
                                            lng: marker.lng 
                                          },
                                          markerIndex : marker.count, 
                                          routeIndex : routeIndex,
                                          accordianData :  marker.pipeContainers 
                                        })
                                      }
                                    }}
                                    pin
                                    icon={{
                                      url: (
                                        marker.type === "Manhole" ? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 20 20">
    <rect width="16" height="12" fill="#ADD8E6" />
  </svg>
`) : 
                                        marker.type === "WayPoint" ? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
                        <rect x="10" y="4" width="4" height="16" fill="#ADD8E6" />
                        <rect x="4" y="10" width="16" height="4" fill="#ADD8E6" />
                      </svg>
                    `):
                                        marker.type === "Pole" ? pole:
                                        marker.type === "comment" ? comment:
                                        marker.type === "location"? location:null),
                                      scaledSize: new window.google.maps.Size(30, 30),
                                      origin: new window.google.maps.Point(0, 0),
                                      anchor: new window.google.maps.Point(15, 15),
                                      labelOrigin: new window.google.maps.Point(15, -10)
                                    }}
                                  />
                                )
                              })
                            }
                            </>
                          )
                        }
                        
                      })
                    )
                  }
                }
                else if(layerResourceID.length >0 && (layerResourceID[layerIndex].role === 'F-003' ||  cookies.get('userData').resourceTypeID === 'T')){
                  return(
                    item.map(({markers},routeIndex) => {
                      if(getSelected('routeIndex',layerIndex,routeIndex).found){
                        return(
                          <>
                          {
                            markers.map((marker,index) => { 
                              return (
                                <Marker
                                  data-count = {marker.count}
                                  draggable = {routeIndex === props.editRouteIndex && edit && props.overallEdit && checkAccess()}
                                  label = {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                  key={`${marker.time.toString()}_${index}`}
                                  position={{ lat: marker.lat, lng: marker.lng }}
                                  onClick={(e) => {
                                    setSelected({
                                      marker,
                                      layerIndex,
                                      routeIndex,
                                      index: index+1,
                                    });
                                    if(innerWidth <= 768 && marker.type === "Manhole"){
                                      setManholeHover({
                                        position:{
                                          lat: marker.lat, 
                                          lng: marker.lng 
                                        },
                                        markerIndex : marker.count, 
                                        routeIndex : routeIndex,
                                        accordianData :  marker.pipeContainers ,
                                        showPipeSide : {
                                          A:false,
                                          B:false,
                                          C:false,
                                          D:false
                                        } 
                                      })
                                    }
                                  }}
                                  onDragEnd = {(e)=>{
                                    onMarkerDragEnd(e,{marker,index:index,routeIndex});
                                  }}
                                  onMouseOver={()=>{
                                    if(marker.type === "Manhole"){
                                      props.getManholeHover({
                                        position:{
                                          lat: marker.lat, 
                                          lng: marker.lng 
                                        },
                                        markerIndex : marker.count, 
                                        routeIndex : routeIndex,
                                        accordianData :  marker.pipeContainers 
                                      })
                                    }
                                  }}
                                  pin
                                  icon={{
                                    url: (
                                      marker.type === "Manhole" ? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 20 20">
                                          <rect width="16" height="12" fill="#ADD8E6" />
                                        </svg>
                                      `) : 
                                                                              marker.type === "WayPoint" ? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
                                                              <rect x="10" y="4" width="4" height="16" fill="#ADD8E6" />
                                                              <rect x="4" y="10" width="16" height="4" fill="#ADD8E6" />
                                                            </svg>
                                                          `):
                                      marker.type === "Pole" ? pole:
                                      marker.type === "comment" ? comment:
                                      marker.type === "location"? location:null),
                                    scaledSize: new window.google.maps.Size(30, 30),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 15),
                                    labelOrigin: new window.google.maps.Point(15, -10)
                                  }}
                                />
                              )
                            })
                          }
                          </>
                        )
                      }
                      
                    })
                  )
                }
                
              }
            })
          }
          {
            layers.map((item,index)=>{
              if(getSelected('layerIndex',index).found){
                if(layerResourceID.length> 0 && layerResourceID[index].role === 'F-005'){
                  if(layerResourceID[index].resourceID === inputResourceID || 
                    layerResourceID[index].companyID === inputResourceID || 
                    cookies.get('userData').resourceTypeID === 'T'||
                    cookies.get('userData').subscriberTypeID === "F-003"){
                    return(
                      item.map(({markers,color},innerIndex)=>{
                        if(getSelected('routeIndex',index,innerIndex).found ){
                          return(
                            <Polyline
                              path={markers}
                              strokeWidth = {10000}
                              options={{strokeColor : color}}
                              strokeWeight={2}
                              onRightClick = {(e)=>{
                                setPolyLineActive(true);
                                onMapRightClick(e)
                            }}/>
                          )
                        }
                      })
                    )
                  }
                }
                else if(layerResourceID.length > 0 && (layerResourceID[index].role === 'F-003' || cookies.get('userData').resourceTypeID === 'T')){
                  return(
                    item.map(({markers,color},innerIndex)=>{
                      if(getSelected('routeIndex',index,innerIndex).found ){
                        return(
                          <Polyline
                            path={markers}
                            strokeWidth = {10000}
                            options={{strokeColor : color}}
                            strokeWeight={2}
                            onRightClick = {(e)=>{
                              setPolyLineActive(true);
                              onMapRightClick(e)
                          }}/>
                        )
                      }
                    })
                  )
                }
              }
              
              
            })
          
        }
        { 
          (selected.marker && !manholeHover) ? (
            <InfoWindow
              position={{ lat: selected.marker.lat, lng: selected.marker.lng }}
              onCloseClick={() => {
                setSelected({
                  marker: null,
                  layerIndex:null,
                  routeIndex : null,
                  index: null
                });
              }}
            >
             <div className={classes.AccordianOption}>
                <h2>{selected.marker.type}- {selected.marker.count}</h2>
                {selected.marker.type === "WayPoint" ? (<Button onClick={()=>{ 
                  if(edit && checkAccess() && props.overallEdit) 
                    deleteMarker(selected.layerIndex,selected.routeIndex,selected.index-1) 
                  else 
                    alert('You don`t have the access to delete')}} 
                  >Delete</Button>)  : null}
                {selected.marker.type === "Pole" ? (<Button onClick={()=>{
                  if(edit && checkAccess() && props.overallEdit) 
                    deleteMarker(selected.layerIndex,selected.routeIndex,selected.index-1)
                  else
                    alert('You don`t have the access to delete')
                  }} >Delete</Button>)  : null}
                {selected.marker.type === "Manhole" ? 
                  <div>
                  { 
                    <Accordian 
                      markerIndex = {selected.index-1} 
                      routeIndex = {selected.routeIndex}
                      accordianData =  {selected.marker.pipeContainers} 
                      handleAccordianData={handleAccordianData}
                      edit = {edit && checkAccess() && props.overallEdit}
                      assetSubComponentSubCategory={props.caseDetails.caseMaster[0].assetSubComponentSubCategory}
                      deleteMarker = {()=>{
                        if (edit && checkAccess() && props.overallEdit)  
                          deleteMarker(selected.layerIndex,selected.routeIndex,selected.index-1)
                        else
                          alert('You don`t have the access to delete')
                      }} 
                    />
                  }
                  </div>                    
                : null
              }   
              {selected.marker.comment?(
                <div>
                  <div style={{display:'flex',justifyContent:'space-evenly', alignItems:'center'}}>
                    <div style={{fontSize:18, fontWeight:'bold',margin:10}}>Comments</div>
                    {showComment ? (<KeyboardArrowUpIcon onClick = {()=>setShowComment(false)}/>) : (<KeyboardArrowDownIcon onClick = {()=>setShowComment(true)}/>)}
                  </div>
                  
                  {showComment && selected.marker.comment.map(item=>{
                    return(
                      <div style = {{margin:5, padding:5, backgroundColor:'aliceblue'}}>
                        <div style = {{display:'flex', justifyContent:'space-between'}}>
                          <p style = {{color:'#aaa'}}>{item.resourceID}</p>
                          <p style = {{color:'#aaa'}}>{new Date(item.timestamp).toLocaleString()}</p>
                        </div>
                        <div>
                          {item.uri ? <img 
                            src={item.uri} 
                            style = {{width:256,height:256, padding:5}}
                            onClick = {()=>{
                              setOpenZoomImage({
                                status:true,
                                uri:item.uri
                              })
                            }}
                          />:null}
                          <p style= {{padding:5, textAlign:'left'}}>{item.commentText}</p>
                       </div>
                      </div>
                    )
                  })}
                </div>
              ):null}     
              </div>
            </InfoWindow>
          ) : null
        }

        {manholeHover ? (
          <InfoWindow
            position={{lat:manholeHover.position.lat,lng:manholeHover.position.lng}}
            onCloseClick={()=>{
              setManholeHover(null);
              setSelected({
                routeIndex : null,
                layerIndex:null,
                marker: null,
                index: null,
              });
            }}
            style = {{width:350}}
            
          >
            <div style={{zoom:0.5, padding:5}}>
              <div>
                <div style={{padding:5, border:'1px solid', margin:'10px auto', borderRadius:'50%', width:100, textAlign:'center',}}>
                  <p>B - Branch 1</p>
                </div>
                <div style={{display:'flex'}}>
                  <div>
                    <div style={{padding:5, border:'1px solid', margin:10, borderRadius:'50%', width:100}}>
                      <p>A - Incoming Fibre</p>
                    </div>
                  </div>
                  
                  <div style={{background:'teal', padding:10, margin:'10px auto'}}>
                    <p style={{color:'white'}}>Manhole - {manholeHover.markerIndex}</p>
                  </div>
                  <div style={{padding:5, border:'1px solid', margin:10, borderRadius:'50%', width:100}}>
                    <p>C - Outgoing Fibre</p>
                  </div>
                </div>
                
                <div style={{padding:5, border:'1px solid', margin:'10px auto', borderRadius:'50%', width:100,textAlign:'center'}}>
                  <p>D - Branch 2</p>
                </div>
              </div>
              <div style={{height:200, overflow:'auto', padding:'0px 10px'}}>
                {Object.keys(manholeHover.accordianData).map(key=>{
                  return(
                    <div style={{borderBottom:'1px solid teal'}}>
                      <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                        <p style={{textAlign:'left', fontWeight:'bold', color:'teal'}}>Side {key}</p>
                        {manholeHover.showPipeSide[key]? (<KeyboardArrowUpIcon  style = {{color:'teal'}} onClick = {()=>{
                          let tempmanholeHover = {... manholeHover};
                          tempmanholeHover.showPipeSide[key]= false;
                          setManholeHover(tempmanholeHover);
                        }}/>):(<KeyboardArrowDownIcon style = {{color:'teal'}} onClick = {()=>{
                          let tempmanholeHover = {... manholeHover};
                          tempmanholeHover.showPipeSide[key]= true;
                          setManholeHover(tempmanholeHover);
                        }}/>)}
                      </div>  
                      {manholeHover.showPipeSide[key] ? (
                        <table style={{width:'100%',background:'aliceblue'}}>
                          <tr>
                            <th style={{width:'75%'}}>Type</th>
                            <th>Quantity</th>
                          </tr>
                          {manholeHover.accordianData[key].map((item,index)=>{
                            return(
                              <tr>
                                <td>{item.PipeType}</td>
                                <td>{item.Quantity}</td>
                              </tr>
                            )
                          })
                          }
                        </table>
                      ):null}
                      
                      
                    </div>
                  )
                })}
              </div>
            </div>
          </InfoWindow>
        ):null}
        
        {
          openZoomImage.status?(
            <div style = {{
              position: "absolute",
              top: "0.2rem",
              right: "4rem",
              background:'none'
            }}>
              <ImgsViewer
                imgs={[
                  { src: openZoomImage.uri }
                ]}
                currImg={openZoomImage.uri}
                isOpen={true}
                onClose={setOpenZoomImage({status:false,uri:null})}
              />
            </div>
          ):null
        }
        {/* BOQ icon */}
        <div 
          style = {{
            position: "absolute",
            top: "0.2rem",
            right: "8rem",
            background:'none'
          }}
        >
          <Button
            variant="contained"
            onClick = {()=>{setShowDetailedBOQ(true)}}
            size = "small"
            style={{background:'teal'}}
          >BOQ</Button>
        </div>
        
        {showDetailedBOQ ? (
          <div style={{
            position:'absolute', 
            top:10,
            left:'10%', 
            right:'15%',
            background:'white', 
            overflow:'auto',
            padding:10,
            maxHeight:450,
            overflow:'auto'
          }}>
            {/* <DownloadTableExcel
              filename="BOQ"
              sheet="BOQ"
              currentTableRef={tableRef.current}
            >
              <DownloadIcon style = {{float:'left', cursor:'pointer'}} />
            </DownloadTableExcel> */}
            
            <DownloadIcon style = {{float:'left', cursor:'pointer'}} 
              onClick = {()=>downloadToExcel()}
            />
            
            <CloseIcon style = {{float:'right', cursor:'pointer'}} onClick = {()=>setShowDetailedBOQ(false)}/>
            <h3>Bill Of Quantity</h3>
            <TableContainer component={Paper} ref={tableRef}>
              <Table sx={{ minWidth: 456 }}  aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Unit</TableCell>  
                    <TableCell>Length</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  Object.keys(getDetailedBOQ().countDesc).map(key=>{
                    return(
                      <TableRow
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell >{key}</TableCell>
                        <TableCell >numbers</TableCell>
                        <TableCell >
                          {getDetailedBOQ().countDesc[key]}
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
                {
                  Object.keys(getDetailedBOQ().pipeDesc).map(key=>{
                    let sum = 0;
                    return(
                      <TableRow
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell >{key}</TableCell>
                        <TableCell >meters</TableCell>
                        <TableCell >
                          {
                            displaySumForBOQ(getDetailedBOQ().pipeDesc[key])
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
                </TableBody>
              </Table>
            </TableContainer>

            {/* Materials */}

            <div>
              <div>
                <p style={{fontWeight:'bold'}}>Materials</p>
              </div>
              {materialofBOQ.map((item,index)=>{
                return(
                  <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', margin:5}}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={JSON.stringify({item:item.item,unit:item.unit,catalogue:item.catalogue})}
                        label="Item *"
                        onChange={(e)=>handleChangeMaterial(index,'item',e.target.value)}
                        style = {{margin:5, width:'50%'}}
                        size = "small"
                        disabled = {(cookies.get('userData').resourceTypeID === 'T' ||cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                        >
                          {materialItem && materialItem.map(item1=>{
                              return(
                                  <MenuItem  value = {JSON.stringify({item:item1.assetSubComponentID,unit:item1.unit,catalogue:item1.catalogue})}><p style={{padding:'5px 10px', cursor:'pointer', margin:0}}>{item1.assetSubComponentName} - {item1.assetSubComponentVarieties}</p></MenuItem>
                              )
                          })}
                    </Select>
                    <TextField
                      id="outlined-basic"
                      label="Unit *"
                      variant="outlined"
                      size="small"
                      value={item.unit?item.unit:''}
                      style={{ margin: 10 }}
                      onChange={(e)=>handleChangeMaterial(index,'unit',e.target.value)}
                      disabled = {true}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Quantity *"
                      variant="outlined"
                      size="small"
                      value={item.quantity?item.quantity:''}
                      style={{ margin: 10 }}
                      onChange={(e)=>handleChangeMaterial(index,'quantity',e.target.value)}
                      disabled = {(cookies.get('userData').resourceTypeID === 'T' ||cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                    />
                    <DownloadIcon
                      style={{cursor:'pointer'}}
                      onClick = {()=>window.open(item.catalogue,"_blank")}
                    />
                    <AddIcon
                      disabled = {(cookies.get('userData').resourceTypeID === 'T' ||cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                      style={{cursor:'pointer'}}
                      onClick = {()=>{
                        let tempMaterialofBOQ = [...materialofBOQ];
                        if(tempMaterialofBOQ[tempMaterialofBOQ.length-1].item && tempMaterialofBOQ[tempMaterialofBOQ.length-1].item){
                          tempMaterialofBOQ.push({
                            item:null,
                            quantity:null
                          })
                          setMaterialofBOQ(tempMaterialofBOQ);
                        }
                        else{
                          setError('Please fill above items');
                        }
                      }}
                    />
                    <DeleteIcon
                      disabled = {(cookies.get('userData').resourceTypeID === 'T' ||cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                      style={{cursor:'pointer'}}
                      onClick = {()=>{
                        let tempMaterialofBOQ = [...materialofBOQ];
                        if(index === 0){
                          tempMaterialofBOQ[index].item = null;
                          tempMaterialofBOQ[index].quantity = null;
                        }
                        else{
                          if (index !== -1) {
                            tempMaterialofBOQ.splice(index, 1);
                          }
                        }
                        setMaterialofBOQ(tempMaterialofBOQ);
                      }}
                    />
                    
                  </div>
                )
              })}
              {/* <p style={{color:'red',fontSize:'small', textAlign:'center'}}>{error}</p> */}
            </div>

            {/* Services */}

            <div>
              <div>
                <p style={{fontWeight:'bold'}}>Services</p>
              </div>
              {servicesofBOQ.map((item,index)=>{
                return(
                  <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', margin:5}}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={JSON.stringify({item:item.item,unit:item.unit,catalogue:item.catalogue})}
                      label="Item *"
                      onChange={(e)=>handleChangeServices(index,'item',e.target.value)}
                      style = {{margin:5, width:'50%'}}
                      size = "small"
                      disabled = {(cookies.get('userData').resourceTypeID === 'T' || cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                      >
                        {serviceItem && serviceItem.map((item1,index1)=>{
                          return(
                            <MenuItem value = {JSON.stringify({item:item1.assetSubComponentID,unit:item1.unit,catalogue:item1.catalogue})} ><p style={{padding:'5px 10px', cursor:'pointer', margin:0}}>{item1.assetSubComponentName} - {item1.assetSubComponentVarieties}</p></MenuItem>
                          )
                        })}
                    </Select>
                    <TextField
                      id="outlined-basic"
                      label="Unit *"
                      variant="outlined"
                      size="small"
                      value={item.unit?item.unit:''}
                      style={{ margin: 10 }}
                      disabled = {true}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Quantity *"
                      variant="outlined"
                      size="small"
                      value={item.quantity?item.quantity:''}
                      style={{ margin: 10 }}
                      onChange={(e)=>handleChangeServices(index,'quantity',e.target.value)}
                      disabled = {(cookies.get('userData').resourceTypeID === 'T' ||cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                    />
                    <DownloadIcon
                      style={{cursor:'pointer'}}
                      onClick = {()=>window.open(item.catalogue,"_blank")}
                    />
                    <AddIcon
                      disabled = {(cookies.get('userData').resourceTypeID === 'T' ||cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                      style={{cursor:'pointer'}}
                      onClick = {()=>{
                        let tempServicesofBOQ = [...servicesofBOQ];
                        if(tempServicesofBOQ[tempServicesofBOQ.length-1].item && tempServicesofBOQ[tempServicesofBOQ.length-1].item){
                          tempServicesofBOQ.push({
                            item:null,
                            quantity:null
                          })
                          setServicesofBOQ(tempServicesofBOQ);
                        }
                        else{
                          setError('Please fill above items');
                        }
                      }}
                    />
                    <DeleteIcon
                      disabled = {(cookies.get('userData').resourceTypeID === 'T' ||cookies.get('userData').subscriberTypeID === "F-003") ? false:true}
                      style={{cursor:'pointer'}}
                      onClick = {()=>{
                        let tempServicesofBOQ = [...servicesofBOQ];
                        if(index === 0){
                          tempServicesofBOQ[index].item = null;
                          tempServicesofBOQ[index].quantity = null;
                        }
                        else{
                          if (index !== -1) {
                            tempServicesofBOQ.splice(index, 1);
                          }
                        }
                        setServicesofBOQ(tempServicesofBOQ);
                      }}
                    />
                    
                  </div>
                )
              })}
              <p style={{color:'red',fontSize:'small', textAlign:'center'}}>{error}</p>
            </div>

          </div>
        ):null}

        </GoogleMap>
      )}
      </ContextMenuTrigger>

      {/* there will be the error in event  */}
      {
        edit  ? <ContextMenu hideOnLeave={true} id="contextmenu">
        <div style={contextMenuStyle}>
        {
         polyLineActive && props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Underground01' && <MenuItem onClick={(e)=>addMarker(e)}>
          <div style={contextMenuItemStyle} >
            <img src={dot} alt="Manhole" style={{ width: "26px" }} />
            <span data-marker-type="Manhole">Manhole</span>
          </div>
        </MenuItem> 
        }
        { 
          !polyLineActive  && <MenuItem onClick={(e)=>addMarker(e)}>
            <div style={contextMenuItemStyle} >
              {(props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Underground01' && props.overallEdit && checkAccess()) ? (
                <>
                  <img src={line} alt="Manhole" style={{ width: "26px" }} />
                  <span data-marker-type="WayPoint">Way Point</span>
                </>
              ):(
                (props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Aerial01' && props.overallEdit && checkAccess())?(
                  <>
                  <img src={line} alt="pole" style={{ width: "26px" }} />
                  <span data-marker-type="WayPoint">Pole</span>
                </>
                ):null
                
              )}
              
            </div>
          </MenuItem>
        }
        </div>
      </ContextMenu> : null
      }

      {edit && manholePopBox ? (
        <div
          style={{
            position: "absolute",
            top: 80,
            right: 10,
            background: "white",
            borderRadius: 5,
            zoom: 0.75,
          }}
        >
          <div>
            <CloseIcon
              onClick={() =>{
                setManholePopBox(false)
                setLineActive(false)
              }}
              style={{
                cursor: "pointer",
                fontSize: "small",
                float: "right",
                padding: 5,
              }}
            />
          </div>
          <div>
            <Button
              variant="contained"
              style={lineActive ? { backgroundColor : "grey",margin: 10 } : {margin:10}}
              onClick={!lineActive ? ()=>setLineActive(true) : null }
              // disabled={lineActive}
            >
              Start
            </Button>
            <Button
              variant="contained"
              style={!lineActive ? { backgroundColor : "grey",margin: 10 } :{ margin: 10 }}
              onClick={lineActive ? ()=>{
                setLineActive(false) 
              }: null}
              // disabled={!lineActive}
            >
              Stop
            </Button>
            <SketchPicker
            color={lineColor}
            onChangeComplete={(color)=> {
              setLineColor(color.hex)
              const tempLayers = [...layers];
              const tempMarkerGrp = tempLayers[props.editLayerIndex]
              tempMarkerGrp[props.editRouteIndex].color =color.hex;
              
              tempLayers[props.editLayerIndex] = [...tempMarkerGrp];
              setLayers(tempLayers)
            }}
            />
          </div>
          <div style={{ display: "grid" }}>
            {/* <TextField
              id="outlined-basic"
              label="Type"
              variant="outlined"
              size="small"
              style={{ margin: 10 }}
              // value={tempLineType}
              // onChange={(e) => tempLineTypeHandler(e)}
            />
            <TextField
              id="outlined-basic"
              label="Comment"
              variant="outlined"
              size="small"
              style={{ margin: 10 }}
              // value={tempLineComment}
              // onChange={(e) => tempLineCommentHandler(e)}
            /> */}

            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={addRoute}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : null}

      <div 
        className={classes.MapOption}>
        <div style={{display:'flex'}}>
          <img 
            src = {roadMap} 
            alt = "RoadMap"
            style = {
              props.mapView === 'roadmap' ? 
              mapViewStyle: {width:50,margin:10,cursor:"pointer"}
            } 
            onClick = {()=>props.setMapView('roadmap')}/>
          <img 
            src = {satellite} 
            alt = "Satellite"
            style = {
              props.mapView === 'satellite' ?
              mapViewStyle: {width:50,margin:10,cursor:"pointer"}
            } 
            onClick = {()=>props.setMapView('satellite')}/>
        </div>
      </div>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <button
      className={classes.locate}
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <img src={compass} alt="compass" />
    </button>
  );
}


function Search({ panTo }) {
  const {
    ready, //similarly like loadScript for googlemapAPI, if its ready
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 28.70406, lng: () => 77.102493 }, //prefer location close to user location
      radius: 100 * 1000,
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  // const handleSelect = async (address) => {
  //   setValue(address, false);
  //   clearSuggestions();

  //   try {
  //     const results = await getGeocode({ address });
  //     const { lat, lng } = await getLatLng(results[0]);
  //     panTo({ lat, lng });
  //   } catch (error) {
  //   }
  // };

  return (
    <div 
    className={classes.search}
    >
      <Combobox >
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Search your location"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

//POlyLine in Google Maps API

// layers [] where every element is markerGrp
// markerGrp [] where every element is {
   // markers : []
   //dataCount : {}
   // lineColor : 
//}