import axios from "../../../../axios_tteg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InformationTable from "./InformationTable";
import Modal from "@mui/material/Modal";

import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";

const ProjectAssignment = (data) => {
    const [projectData, setProjectData] = useState([]);
    const [casedata, setCasedata] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedCaseID, setSelectedCaseID] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const handleIconClick = (caseID) => {
      setSelectedCaseID(caseID); // Set the caseID to pass to InformationTable
      setOpenModal(true); // Open the modal
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
      setSelectedCaseID(null); // Clear the caseID when closing the modal
    };
    // Fetch project data
    const getProject = async () => {
        try {
            const response = await axios.post("/getProjectDetails2",{resourceID:data.resourceID});
            console.log(response)
            setProjectData(response.data); // Set project data
            // setCasedata(response.data.casedata); // Set case data
        } catch (e) {
            console.log("Error fetching project data", e);
        }
    };

    // Fetch project details based on selected project
    const projectDetails = async () => {
        if (!selectedProject) return;
        try {
            const data = await axios.post("/projectData", { selectedProject });
            console.log("Selected", data.data.data);
            setSelectedData(data.data.data); // Assuming this is an array
        } catch (e) {
            console.log("Error fetching selected project data", e);
        }
    };

    useEffect(() => {
        getProject();
    }, []);

    useEffect(() => {
        projectDetails();
    }, [selectedProject]);

    // Merge project data with case data based on caseID
    const mergedData = projectData
        // .map((project) => {
        //     const caseDetails = casedata.find((caseItem) => caseItem.caseID === project.caseID);
        //     if (caseDetails) {
        //         return { ...project, caseDetails }; // Add caseDetails to project
        //     }
        //     return null;
        // })

    // Handle row click to show the selected project data in the dialog
    const handleRowClick = (item) => {
        setSelectedProject(item);
    };

    // Close the dialog and reset selected project data
    const handleClose = () => {
        setSelectedProject(null);
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Project ID</TableCell>
                            <TableCell>Case ID</TableCell>
                            <TableCell>Case Details</TableCell> {/* Add new column for case details */}
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mergedData.map((item) => (
                            <TableRow
                                key={item._id}
                                hover
                                onClick={() => handleRowClick(item.projectID)} // Handle click on the row
                                style={{ cursor: "pointer" }} // Makes the row look clickable
                            >
                                <TableCell>{item.projectID || 1}</TableCell>
                                <TableCell>{item.caseID}</TableCell>
                                <TableCell>{item.caseDetails[0]?.title || "No Details"}</TableCell> {/* Display case details */}
                                <TableCell>
                {item.caseDetails[0]?.caseDescription || "No Details"} </TableCell>
                <TableCell> <OpenInNewIcon
                style={{
                    right:"5vw",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    borderRadius: "4px", // Optional: Round the corners of the box
                    padding: "4px", // Add some padding for a better look
                    backgroundColor: "transparent", // Optional: Background color for the box
                }}
                aria-label="Redirect Icon"
                onClick={(e) => {
                    e.stopPropagation(); // Prevent row click from firing
                    handleIconClick(item.caseID); // Open modal or perform redirect with caseID
                }}
                /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
 {/* Modal for InformationTable */}
 <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="information-table-modal"
        aria-describedby="shows-detailed-information"
      >
        <div style={{ padding: "20px", background: "#fff", margin: "50px auto", maxWidth: "95vw", overflow:"scroll" }}>
          <InformationTable caseID={selectedCaseID} />
        </div>
      </Modal>
            {/* Dialog to display selected project details */}
            <Dialog open={selectedProject !== null}   maxWidth="xxl" // Makes it large
onClose={handleClose}>
                <DialogTitle>Project Details</DialogTitle>
                <DialogContent>
                    {/* Loop through selectedData (which is an array) and display each item's details */}
                    {selectedData.length > 0 ? (
                        selectedData.map((dataItem, index) => (
                            <div key={index}>
                                <p><strong>Resource ID:</strong> {dataItem.resourceID}</p>
                                <p><strong>Start Date:</strong> {dataItem.startDate||""}</p>
                                <p><strong>End Date:</strong> {dataItem.endDate||""}</p>
                                {/* Add more fields as required */}
                            </div>
                        ))
                    ) : (
                        <p>No details available.</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProjectAssignment;
