import React, { useEffect, useState } from "react";
import axios from "../../../../axios_tteg";
import { GoogleMap, useLoadScript, Marker, InfoWindow, Polyline } from "@react-google-maps/api";
import InfoIcon from "@mui/icons-material/Info"; // Import InfoIcon from Material UI
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import image1 from "../../../../assets/images/branch.png"
import comment from "../../../../assets/images/comment.png"
// Google Maps API settings
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const defaultCenter = {
  lat: 0, // Default latitude
  lng: 0, // Default longitude
};

const InformationTable = (data) => {
  console.log(data,"lp")
  const [projectPlanData, setProjectPlanData] = useState([]);
  const [plannedDataLocation, setPlannedDataLocation] = useState([]);
  const [plannedBoq,setPlannedBoq]=useState([])
  const [surveyData, setSurveyData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarker2, setSelectedMarker2] = useState(null);
  const [selectedActualPoint,setselectedActualPoint]=useState(null)
  const [selectedPlanPoint,setselectedPlanPoint]=useState(null)
  const [statusData,setStatusData]=useState([])
  const [projectType,setProjectType]=useState("")
  const [detailedBOQProject,setDetailBOQProject]=useState([])
  const [open, setOpen] = useState(false);
  const [showPlannedMap, setShowPlannedMap] = useState(true);
  const [showActualMap, setShowActualMap] = useState(true);
  const [showPlannedTable, setShowPlannedTable] = useState(true);
  const [showActualTable, setShowActualTable] = useState(true);
  const [locationOption,setLocationOption]=useState("Map")
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [openItem, setopenItem] = useState(false);
  const [projectID,setProjectID]=useState(null)
  const [path,setPath]=useState([])
  const [path2,setPath2]=useState([])
  const [centerActual,setCenterActual]=useState({})
  const [centerPlan,setCenterPlan]=useState(true)
  const [selectedOption, setSelectedOption] = useState("1");
  const [subprojectData,setSubprojectData]=useState([])
  const [hddTrackerData,setHddTrackerData]=useState([])
  const [hddBoqData,setHddBoqData]=useState([])
  const [materialBOQ,setMaterialBOQ]=useState([])
  const [serviceBOQ,setServiceBOQ]=useState([])
  const [selectedActual,setSelectedActual]=useState(null)
  const [openActual,setOpenActual]=useState(false)
const handleChange = (event) => {
  setSelectedOption(event.target.value);
};
  const handleInfoIconClick = (item) => {
    setSelectedItem(item); // Set the item to be displayed in the modal
    setOpen(true); // Open the modal
  };
  const handleInfoIconClick2 = (item) => {
    setSelectedItem2(item); // Set the item to be displayed in the modal
    setopenItem(true); // Open the modal
  };
  const handleActualIconClick = (item) => {
    setSelectedActual(item); // Set the item to be displayed in the modal
    setOpenActual(true); // Open the modal
  };
  const getTrackerTabledata=async(id,subProjectNO)=>{
    try{
      const response=await axios.post("/getTrackerTableData",{caseID:id,subProjectNo:subProjectNO})
      console.log(response.data[0].projectID,"oks")
      setProjectID(response.data[0].projectID)
      setHddTrackerData(response.data[0].subprojectData[0])
      setHddBoqData(response.data[0].subprojectData[0].BOQData)
      // console.log(response.data[0].subprojectData[0],"rres")
    }
    catch(e){
      console.log(e)
    }
  }
  const handleCloseModal = () => {
    setOpen(false); // Close the modal
    setSelectedItem(null); // Clear selected item
  };
  const handleCloseModal2 = () => {
    setopenItem(false); // Close the modal
    setSelectedItem2(null); // Clear selected item
  };
  
  const handleActualModal = () => {
    setOpenActual(false); // Close the modal
    setSelectedActual(null); // Clear selected item
  };
  const getProjectType=async(id)=>{
    try{
    const response=await axios.post("/getProjectType",{caseID:id})
    setProjectType(response.data)
    }
    catch(e){
      console.log(e)
    }
  }
 
  const getDetailedBOQ = ()=>{
    let pipeDesc = {};
    let countDesc = {};
    let MHContainer = [];
    // layers[0].map((layer,layerIndex)=>{
      plannedDataLocation?.map((marker,markerIndex)=>{
        if(marker.type === 'Manhole'){
          if(countDesc[marker.type]){
            countDesc[marker.type] = countDesc[marker.type]+1;
          }
          else{
            countDesc[marker.type] = 1;
          }
          MHContainer.push(marker);
        }
      })
      for(let i = 0;i < MHContainer.length; i = i+1){
        if(i+1 < MHContainer.length){
          MHContainer[i].pipeContainers.C.map((innerItem,innerIndex)=>{
            if(pipeDesc[innerItem.PipeType]){
              pipeDesc[innerItem.PipeType].push({
                quantity:innerItem.Quantity,
                length : distBtwPoints(
                  {
                    lat:MHContainer[i].lat,
                    lng:MHContainer[i].lng
                  },{
                    lat:MHContainer[i+1].lat,
                    lng:MHContainer[i+1].lng
                  })
              }) 
            }
            else{
              pipeDesc[innerItem.PipeType] = [{
                quantity:innerItem.Quantity,
                length : distBtwPoints(
                  {
                    lat:MHContainer[i].lat,
                    lng:MHContainer[i].lng
                  },{
                    lat:MHContainer[i+1].lat,
                    lng:MHContainer[i+1].lng
                  }
                )
              }]
            }
            
          })
        }
      }
    return({
      pipeDesc:pipeDesc,
      countDesc:countDesc
    })
}

const getProjectToSubprojectDetails=async(id)=>{
  try{
  const response = await axios.post("/getProjectToSubprojectDetails",{caseID:id})
   setSubprojectData(response.data[0])
  }
  catch(e){
    console.log(e)
  }
}
const distBtwPoints = (coords1, coords2) => {
  const R = 6371e3; // metres
  const φ1 = parseFloat(coords1.lat) * Math.PI / 180; // φ, λ in radians
  const φ2 = parseFloat(coords2.lat) * Math.PI / 180;
  const Δφ = (parseFloat(coords2.lat) - parseFloat(coords1.lat)) * Math.PI / 180;
  const Δλ = (parseFloat(coords2.lng) - parseFloat(coords1.lng)) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
     Math.cos(φ1) * Math.cos(φ2) *
     Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // in metres
}

  const getPlanningData = async (id) => {
    try {
      const response = await axios.post("/getProjectPlan", {
        caseID: id, // Replace with actual caseID
      });
      const projectData = response.data[0]?.ProjectPlanningData;

      projectData?.forEach(async (item) => {
        if (item.resourceID.includes("F003")) {
          setProjectPlanData(item);
          setPlannedDataLocation(item.data[0]?.markers || []);
          // console.log(item,"item")
          setMaterialBOQ(item.materialofBOQ)
          setServiceBOQ(item.serviceofBOQ)
          

        }
        if (item.resourceID.includes("F005")) {
          setSurveyData(item.data[0].markers);
        }
      });
    } catch (error) {
      console.error("Error fetching project plan data:", error);
    }
  };

  const getProject=async()=>{
    // setLoading(true)
    if(projectID){
        try{
        const response= await axios?.post("/getOverallProgressProject",{projectID:projectID,subProjectNo:selectedOption})
        console.log(response.data,"pllp")
        setStatusData(response.data)
        // setChecked(response?.data[0]?.status)
    }
    catch(e){
        console?.log(e)
    }}
    // setLoading(false)
} 
useEffect(()=>{getProject()},[projectID,selectedOption])
useEffect(()=>{    getTrackerTabledata(data.caseID,selectedOption)
},[data.caseID,selectedOption])
  useEffect(() => {
    getPlanningData(data.caseID);
    getProjectType(data.caseID)
    getProjectToSubprojectDetails(data.caseID)
    const datas=  getDetailedBOQ()
          setDetailBOQProject(datas)
  }, [data.caseID]);
  useEffect(()=>{
    setDetailBOQProject(getDetailedBOQ())
    const paths = plannedDataLocation.map((project) => ({
      lat: project.lat,
      lng: project.lng,
    }));
    setPath(paths)
  },[plannedDataLocation])
  useEffect(() => {
    // Ensure hddTrackerData is not null or undefined
    if (hddTrackerData?.trackingData) {
      const paths = hddTrackerData.trackingData.map((item) => 
        item.map((project) => ({
          lat: project.lat,
          lng: project.lng,
        }))
      );
  
      // Flatten the array if needed (depends on how you plan to use `paths`)
      // const flattenedPaths = paths.flat();
      // console.log(paths,"paths")
      setPath2(paths); // Update state with flattened data
    }
  }, [hddTrackerData]);
  

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC_kRxUGZMpYA361uWGeGohZW5PYqCoj4k",
    libraries,
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      {projectPlanData.length === 0 && plannedDataLocation.length === 0 && detailedBOQProject.length ===0 && surveyData.length===0 ? (
        <h3>Loading...</h3>
      ) : (
        <>
        <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "20px" }}>
        <label>
            <input
              type="checkbox"
              checked={showPlannedTable}
              onChange={() => setShowPlannedTable(!showPlannedTable)}
            />
            Show Planned Table
          </label>
          <label>
            <input
              type="checkbox"
              checked={showActualTable}
              onChange={() => setShowActualTable(!showActualTable)}
            />
            Show Actual Table
          </label>
    
          <label>
            <input
              type="checkbox"
              checked={showPlannedMap}
              onChange={() => setShowPlannedMap(!showPlannedMap)}
            />
            Show Planned data
          </label>
          <label>
            <input
              type="checkbox"
              checked={centerPlan}
              onChange={() => setCenterPlan(!centerPlan)}
            />
            Show Actual data
          </label>
          <label>
            <input
              type="checkbox"
              checked={showActualMap}
              onChange={() => {setShowActualMap(!showActualMap)
                setCenterPlan(!centerPlan)
                setShowPlannedMap(!showPlannedMap)
              }}
            />
            Show Map
          </label>
          <table  style={styles.tableContainer}>
            {/* <thead style={styles.TableHead}>
              <th colSpan={3} style={styles.tableTh}>Status</th>
            </thead> */}
          <thead>
          <th colSpan={1} style={styles.tableTh}></th>
          <th colSpan={1} style={styles.tableTh}>Site Supervisor</th>
          <th colSpan={1} style={styles.tableTh}>Project manager</th>
          </thead>
          <tbody>
            {statusData.length>0 ?(<>
            <tr>
              <td style={styles.tableTd}>Last Modification</td>
              <td style={styles.tableTd}>{statusData[0]?.timestamp.substr(0,28)||"-"}</td>
              <td style={styles.tableTd}>{statusData[1]?.timestamp.substr(0,28)||"-"}</td>
            </tr>
            <tr>
              <td style={styles.tableTd}>Status</td>
              <td style={styles.tableTd}>{statusData[0] ? statusData[0]?.status=="1"?"Fully Completed":"Partially completed":"-"}</td>
              <td style={styles.tableTd}>{statusData[1]? statusData[1]?.status=="1"?"Fully Completed":"Partially completed":"-"}</td>
            </tr>
            </>):(
              <>
              <tr>
              <td style={styles.tableTd}>Last Modification</td>
              <td style={styles.tableTd}>-</td>
              <td style={styles.tableTd}>-</td>
            </tr>
            <tr>
              <td style={styles.tableTd}>Status</td>
              <td style={styles.tableTd}>-</td>
              <td style={styles.tableTd}>-</td>
            </tr>
              </>
            )}
          </tbody>
          </table>
        </div>
        <div style={{...styles.container}}>
              {/* Checkboxes */}
    
          {/* Left Section */}
          <div style={{...styles.leftSection,width:!showPlannedMap && !showActualMap?"5000%":"50%"}}>
            {/* Table for Project Details */}
            
            <div style={styles.projectDetails}>
            { selectedActual?
              <Dialog open={openActual} onClose={handleActualModal} maxWidth="lg">
        <DialogContent style={{ display: 'flex' }}>

          {/* Image on the right */}
          <div style={{ flex: 1, padding: '10px', textAlign: 'center' }}>
            {selectedActual  ? (
              <img src={selectedActual.img.uri} alt={selectedActual.img.name} style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }} />
            ) : (
              <p>No image available</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleActualModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      :null}
              { selectedItem?
              <Dialog open={open} onClose={handleCloseModal} maxWidth="lg">
        <DialogTitle>Details for {selectedItem?.type}</DialogTitle>
        <DialogContent style={{ display: 'flex' }}>
          {/* Table on the left */}
          <div style={{ flex: 1, padding: '10px' }}>
            <TableContainer>
            <Table>
  <TableHead>
    <TableRow>
      <TableCell>Pipe Containers</TableCell>
      <TableCell>Item</TableCell>
      <TableCell>Quantity</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {/* Pipe Containers A */}
    {selectedItem.pipeContainers.A  ? (
             selectedItem.pipeContainers.A.map((item,index)=>(

       <TableRow key={index}>
       <TableCell>A</TableCell>
       <TableCell>{item.PipeType||"-"}</TableCell>
       <TableCell>{item.Quantity||"-"}</TableCell>
     </TableRow>))
     ) : (
       <TableRow>
         <TableCell>A</TableCell>
         <TableCell>-</TableCell>
         <TableCell>-</TableCell>
       </TableRow>
     )}

    {/* Pipe Containers B */}
    {selectedItem.pipeContainers.B  ? (
             selectedItem.pipeContainers.B.map((item,index)=>(
       <TableRow key={index}>
       <TableCell>B</TableCell>
       <TableCell>{item.PipeType||"-"}</TableCell>
       <TableCell>{item.Quantity||"-"}</TableCell>
     </TableRow>
             ))
     ) : (
       <TableRow>
         <TableCell>B</TableCell>
         <TableCell>-</TableCell>
         <TableCell>-</TableCell>
       </TableRow>
     )}

    {/* Pipe Containers C */}
    {selectedItem.pipeContainers.C  ? (
      selectedItem.pipeContainers.C.map((item,index)=>(
       <TableRow key={index}>
       <TableCell>C</TableCell>
       <TableCell>{item.PipeType||"-"}</TableCell>
       <TableCell>{item.Quantity||"-"}</TableCell>
     </TableRow>
      ))
     ) : (
       <TableRow>
         <TableCell>C</TableCell>
         <TableCell>-</TableCell>
         <TableCell>-</TableCell>
       </TableRow>
     )}
    {/* Pipe Containers D */}
    {selectedItem.pipeContainers.D ? (
            selectedItem.pipeContainers.D.map((item,index)=>(
      <TableRow key={index}>
      <TableCell>D</TableCell>
      <TableCell>{item.PipeType||"-"}</TableCell>
      <TableCell>{item.Quantity||"-"}</TableCell>
    </TableRow>
            ))
    ) : (
      <TableRow>
        <TableCell>D</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
      </TableRow>
    )}
  </TableBody>
</Table>

            </TableContainer>
          </div>

          {/* Image on the right */}
          <div style={{ flex: 1, padding: '10px', textAlign: 'center' }}>
            {selectedItem  ? (
              <img src={image1} alt={selectedItem.type} style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }} />
            ) : (
              <p>No image available</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      :null}

{ selectedItem2?
              <Dialog open={openItem} onClose={handleCloseModal2} maxWidth="lg">
        <DialogTitle>Details for {selectedItem2?.type}</DialogTitle>
        <DialogContent style={{ display: 'flex' }}>
          {/* Table on the left */}
          <div style={{ flex: 1, padding: '10px' }}>
            <TableContainer>
            <Table>
  <TableHead>
    <TableRow>
      <TableCell>Pipe Containers</TableCell>
      <TableCell>Item</TableCell>
      <TableCell>Quantity</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {/* Pipe Containers A */}
    {selectedItem2?.pipeConfiguration?.A  ? (
             selectedItem2?.pipeConfiguration?.A?.map((item,index)=>(
       <TableRow key={index}>
       <TableCell>A</TableCell>
       <TableCell>{item.PipeType||"-"}</TableCell>
       <TableCell>{item.Quantity||"-"}</TableCell>
     </TableRow>))
     ) : (
       <TableRow>
         <TableCell>A</TableCell>
         <TableCell>-</TableCell>
         <TableCell>-</TableCell>
       </TableRow>
     )}

    {/* Pipe Containers B */}
    {selectedItem2?.pipeConfiguration?.B  ? (
             selectedItem2?.pipeConfiguration?.B?.map((item,index)=>(
       <TableRow key={index}>
       <TableCell>B</TableCell>
       <TableCell>{item.PipeType||"-"}</TableCell>
       <TableCell>{item.Quantity||"-"}</TableCell>
     </TableRow>
             ))
     ) : (
       <TableRow>
         <TableCell>B</TableCell>
         <TableCell>-</TableCell>
         <TableCell>-</TableCell>
       </TableRow>
     )}

    {/* Pipe Containers C */}
    {selectedItem2?.pipeConfiguration?.C  ? (
      selectedItem2?.pipeConfiguration?.C?.map((item,index)=>(
       <TableRow key={index}>
       <TableCell>C</TableCell>
       <TableCell>{item.PipeType||"-"}</TableCell>
       <TableCell>{item.Quantity||"-"}</TableCell>
     </TableRow>
      ))
     ) : (
       <TableRow>
         <TableCell>C</TableCell>
         <TableCell>-</TableCell>
         <TableCell>-</TableCell>
       </TableRow>
     )}
    {/* Pipe Containers D */}
    {selectedItem2?.pipeConfiguration?.D ? (
            selectedItem2?.pipeConfiguration?.D?.map((item,index)=>(
      <TableRow key={index}>
      <TableCell>D</TableCell>
      <TableCell>{item.PipeType||"-"}</TableCell>
      <TableCell>{item.Quantity||"-"}</TableCell>
    </TableRow>
            ))
    ) : (
      <TableRow>
        <TableCell>D</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
      </TableRow>
    )}
  </TableBody>
</Table>

            </TableContainer>
          </div>

          {/* Image on the right */}
          <div style={{ flex: 1, padding: '10px', textAlign: 'center' }}>
            {selectedItem2  ? (
              <img src={image1} alt={selectedItem2.type} style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }} />
            ) : (
              <p>No image available</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal2} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      :null}

      <div style={{display:"flex",gap: "2vw", marginRight: "2vw" }} >
              {showPlannedTable?(
                <div
                style={{
                  flex: showActualTable ? 1 : 2, // Full width if Actual Map is hidden
                }}>
              <div style={styles.tablePlannedContainer}>
  <table style={styles.table}>
    <thead>
      <tr>
        <th colSpan={3} style={styles.tableTh}>
          Project Details: {data.caseID || ""}
        </th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th style={styles.tableTh}>Attributes</th>
        <th  colSpan={2} style={styles.tableTh}>Planned</th>
      </tr>
    </thead>
    <tbody>
    {projectType==="Underground01"?(<>

      <tr>
        <td rowSpan={1} style={styles.tableTh}>Location Refference</td>
        <td rowSpan={1} style={styles.tableTh}>Latitude</td>
          
        <td rowSpan={1} style={styles.tableTh}>Longitude</td>
        </tr>
          </>):(<>
            <tr>
            <td style={styles.tableTh}>Data</td>
        <td  style={styles.tableTh}>Latitude</td>
        
        <td  style={styles.tableTh}>Longitude</td>
                </tr>
          </>)}
            
      {plannedDataLocation
      ?(<>
      {plannedDataLocation?.map((item,key)=>(
        <tr>
          <td style={{...styles.tableTd,display:"flex"}}>
            <button onClick={()=>{setselectedActualPoint(item)
                             setCenterActual({lat:item.lat,lng:item.lng})}}
                             style={{border:0,backgroundColor:"transparent",width:"100%",padding:0,margin:0,display:"flex",justifyContent:"center",alignItems:"center"}}>
            {item.type}-{item.count}  {item.type === "Manhole" && (
                        <InfoIcon
                          onClick={() => handleInfoIconClick(item)}
                          style={{ cursor: "pointer", color: "#007BFF", marginLeft: "10px" ,display:"flex"}}
                        />
                      )}</button>
          </td>
          <td style={styles.tableTd}>
          {item.lat ? parseFloat(item.lat).toFixed(7) : "-"}
          </td>
          <td style={styles.tableTd}>
          {item.lng ? parseFloat(item.lng).toFixed(7) : "-"}
          </td>
        </tr>
      ))}
      </>)
      :null}
      <tr>
        <th colSpan={3} style={{...styles.tableTh,textAlign:"center"}}>Bill Of Quantity </th>
      </tr>
  {projectType === "Underground01" && Object.keys(detailedBOQProject || {}).length > 0 & Object.keys(detailedBOQProject.pipeDesc || {}).length > 0  ? (
  <>
    {/* Table Headers for Underground01 */}
    <tr>
      <td style={styles.tableTh}>Item</td>
      <td style={styles.tableTh}>Quantity</td>
      {/* <td colSpan={1} style={styles.tableTh}>Unit</td> */}
      <td colSpan={1} style={styles.tableTh}>Length</td>
    </tr>

    {/* Data Rows for Underground01 */}
    {detailedBOQProject && Object.keys(detailedBOQProject?.pipeDesc)?.map((pipeType, index) => (
      <>
        {detailedBOQProject.pipeDesc[pipeType]?.map((item, key) => (
          <tr key={key}>
            <td style={styles.tableTd}>{pipeType || "-"}</td>
            <td style={styles.tableTd}>{item.quantity || "-"}</td>
            {/* <td style={styles.tableTd}>{item.unit || "-"}</td> */}
            <td style={styles.tableTd}>{parseFloat(item.length).toFixed(7) || "-"}</td>
            
          </tr>  

        ))}
      </>
    ))}
  </>
) :null}

{projectType !== "Underground01" & Object.keys(detailedBOQProject || {}).length > 0  ? (
  <>
    {/* Table Headers for Underground01 */}
    <tr>
      <td style={styles.tableTh}>Item</td>
      <td style={styles.tableTh}>Quantity</td>
      {/* <td style={styles.tableTh}>Unit</td> */}
      <td style={styles.tableTh}>Length</td>
    </tr>

    {/* Data Rows for Underground01 */}
    {detailedBOQProject && Object.keys(detailedBOQProject?.pipeDesc)?.map((pipeType, index) => (
      <>
        {detailedBOQProject.pipeDesc[pipeType]?.map((item, key) => (
          <tr key={key}>
            <td style={styles.tableTd}>{pipeType || "-"}</td>
            <td style={styles.tableTd}>{item.quantity || "-"}</td>
            {/* <td style={styles.tableTd}>{item.unit || "-"}</td> */}
            <td style={styles.tableTd}>{item.length || "-"}</td>
          </tr>
        ))}
      </>
    ))}
  </>
) :null}

{projectType === "Underground01" ? (
  <>
  <tr>
        <th colSpan={3} style={{...styles.tableTh,textAlign:"center"}}>Materials of BOQ </th>
      </tr>
    {/* Table Headers for Underground01 */}
    <tr>
      <td style={styles.tableTh}>Item</td>
      <td style={styles.tableTh}>Quantity</td>
      {/* <td style={styles.tableTh}>Unit</td> */}
      <td style={styles.tableTh}>unit</td>
    </tr>

    {/* Data Rows for Underground01 */}
        {materialBOQ?.map((item, key) => (
          <tr key={key}>
            <td style={styles.tableTd}>{item.item || "-"}</td>
            <td style={styles.tableTd}>{item.quantity || "-"}</td>
            {/* <td style={styles.tableTd}>{item.unit || "-"}</td> */}
            <td style={styles.tableTd}>{item.unit || "-"}</td>
          </tr>
        ))}
      
  </>
) :null}
{projectType === "Underground01"? (
  <>
  <tr>
        <th colSpan={3} style={{...styles.tableTh,textAlign:"center"}}>Services of BOQ </th>
      </tr>
    {/* Table Headers for Underground01 */}
    <tr>
      <td style={styles.tableTh}>Item</td>
      <td style={styles.tableTh}>Quantity</td>
      {/* <td style={styles.tableTh}>Unit</td> */}
      <td style={styles.tableTh}>unit</td>
    </tr>

    {/* Data Rows for Underground01 */}
        {serviceBOQ?.map((item, key) => (
          <tr key={key}>
            <td style={styles.tableTd}>{item.item || "-"}</td>
            <td style={styles.tableTd}>{item.quantity || "-"}</td>
            {/* <td style={styles.tableTd}>{item.unit || "-"}</td> */}
            <td style={styles.tableTd}>{item.unit || "-"}</td>
          </tr>
        ))}
      
  </>
) :null}
    </tbody>
  </table>
</div>
</div>)
:null}

{showActualTable?(
  <div
          style={{
            flex: showActualTable ? 1 : 2, // Full width if Actual Map is hidden
          }}>
<div style={styles.tableContainer}>
  <table style={styles.table}>
    <thead>
      <tr>
        <th colSpan={5} style={styles.tableTh}>
          Project Details: {data.caseID || ""}
        </th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th colSpan={2}style={styles.tableTh}>Attributes</th>
        <th colSpan={3} style={styles.tableTh}>Actual &nbsp; &nbsp; &nbsp;&nbsp; <select
              value={selectedOption}
              onChange={handleChange}
              style={styles.dropdown}
            >
              {subprojectData?.data?.length>0?(<>
              {subprojectData?.data?.map((item,key)=>(
              <option value={key+1} >subproject-{key+1}</option>
              
            ))}
            </>)
          :null}
            </select></th>
      </tr>
    </thead>
    <tbody>
    {projectType==="Underground01"?(<>

      <tr>
      <td rowSpan={2} style={styles.tableTh}>Operation Type </td>

        <td rowSpan={2} style={styles.tableTh}>Location Refference</td>
                <td colSpan={3} style={styles.tableTh}>HDD and opentrench &nbsp; &nbsp; &nbsp;&nbsp; <select
              value={locationOption}
              onChange={(event)=>{  setLocationOption(event.target.value);
              }}
              style={styles.dropdown}
            >
             
              <option value="Map" >Map Location</option>
              <option value={"Geotron"} >Geotron Location</option>

            </select></td>
                {/* <td colSpan={2} style={styles.tableTh}>Opentrench &nbsp; &nbsp; &nbsp;&nbsp; <select
              value={locationOption}
              onChange={(event)=>{  setLocationOption(event.target.value);
              }}              style={styles.dropdown}
            >
             
              <option value="Map" >Map Location</option>
              <option value={"Geotron"} >Geotron Location</option>

            </select></td> */}
        </tr>
        <tr>
         
                <td style={styles.tableTh}>{locationOption} Latitude</td>
                <td style={styles.tableTh}>{locationOption} Longitude</td>
                <td style={styles.tableTh}>Depth</td>

               
                {/* <td style={styles.tableTh}>{locationOption} Latitude</td>
                <td style={styles.tableTh}>{locationOption} Longitude</td> */}
                </tr>
          </>):(<>
            <tr>
            <td style={styles.tableTh}>Data</td>

                <td style={styles.tableTh}>{locationOption} Latitude</td>
                <td style={styles.tableTh}>{locationOption} Longitude</td>
             
                </tr>

          </>)}
            
      {hddTrackerData
      ?(<>
      {hddTrackerData?.trackingData?.map((item1,key1)=>{
       return   item1?.map((item,key)=>(
        
        <tr>
                    {/* {console.log(item.type,"item1")} */}
              
                    <td style={styles.tableTd}>HDD</td>

          <td style={{...styles.tableTd,display:"flex"}}>
            <button onClick={()=>{setselectedActualPoint(item)
               setCenterActual({lat:item.lat,lng:item.lng})}}
                style={{border:0,backgroundColor:"transparent",width:"100%",padding:0,margin:0,display:"flex",justifyContent:"center",alignItems:"center"}}>
            {item.type}-{item.type=="Tracking Point"?item.count-1:item.count}  {item.img&&(
                        <InfoIcon
                          onClick={() => handleActualIconClick(item)}
                          style={{ cursor: "pointer", color: "#007BFF", marginLeft: "10px" ,display:"flex"}}
                        />
                        
                      )} 
                      {(item.type=="Entry Pit" || item.type=="Exit Pit") && (
                          <InfoIcon
                            onClick={() => handleInfoIconClick2(item)}
                            style={{ cursor: "pointer", color: "#007BFF", marginLeft: "10px" ,display:"flex"}}
                          />
                        )}</button>
          </td>
          <td style={styles.tableTd}>
            {locationOption=="Map"?item.mobileLat!=0?parseFloat(item.mobileLat).toFixed(7):"-":item.geotronLat!=0?parseFloat(item.geotronLat).toFixed(7):"-"}
          </td><td style={styles.tableTd}>
          {locationOption=="Map"?item.mobileLng!=0?parseFloat(item.mobileLng).toFixed(7):"-":item.geotronLng!=0?parseFloat(item.geotronLng).toFixed(7):"-"}
          </td>
          <td style={styles.tableTd}>
            {item?.data?.depth||"-"}
          </td>
        </tr>
      ))})}
      </>)
      :null}







{/* 
{hddTrackerData
      ?(<>
      {hddTrackerData?.trackingData?.map((item1,key1)=>{
       return   item1?.map((item,key)=>(
        
        <tr>
                    {console.log(item.type,"item1")}
              
                    <td style={styles.tableTd}>HDD</td>

          <td style={{...styles.tableTd,display:"flex"}}>
          <button onClick={()=>{setselectedActualPoint(item)        
                 setCenterActual({lat:item.lat,lng:item.lng})}}
 style={{border:0,backgroundColor:"transparent",width:"100%",padding:0,margin:0,display:"flex",justifyContent:"center",alignItems:"center"}}>
            {item.type}-{item.type=="Tracking Point"?item.count-1:item.count}  {item.img&&(
                        <InfoIcon
                          onClick={() => handleActualIconClick(item)}
                          style={{ cursor: "pointer", color: "#007BFF", marginLeft: "10px" ,display:"flex"}}
                        />
                      )} </button>
          </td>
          <td style={styles.tableTd}>
            {locationOption=="Map"?item.mobileLat!=0?parseFloat(item.mobileLat).toFixed(7):"-":item.geotronLat!=0?parseFloat(item.geotronLat).toFixed(7):"-"}
          </td><td style={styles.tableTd}>
          {locationOption=="Map"?item.mobileLng!=0?parseFloat(item.mobileLng).toFixed(7):"-":item.geotronLng!=0?parseFloat(item.geotronLng).toFixed(7):"-"}
          </td>
          <td style={styles.tableTd}>
          {item?.data?.depth||"-"}
          </td>
        </tr>
      ))})}
      </>)
      :null} */}
      <tr>
        <th colSpan={1} style={{...styles.tableTh,textAlign:"center"}}> </th>
        <th colSpan={2} style={{...styles.tableTh,textAlign:"center"}}>Bill Of Quantity </th>
        <th colSpan={2} style={{...styles.tableTh,textAlign:"center"}}>Bill Of Quantity </th>

      </tr>
  {projectType === "Underground01" && Object.keys(detailedBOQProject || {}).length > 0 & Object.keys(detailedBOQProject.pipeDesc || {}).length > 0  ? (
  <>
    {/* Table Headers for Underground01 */}
    <tr>
      <td style={styles.tableTh}>Item</td>
      
      <td colSpan={2} style={styles.tableTh}>Quantity</td>
      {/* <td colSpan={1} style={styles.tableTh}>Unit</td> */}
      <td colSpan={2} style={styles.tableTh}>Length</td>
      {/* <td colSpan={1} style={styles.tableTh}>Quantity</td> */}
      {/* <td colSpan={1} style={styles.tableTh}>Unit</td> */}
      {/* <td colSpan={1} style={styles.tableTh}>Length</td> */}
    </tr>

    {/* Data Rows for Underground01 */}
    {hddTrackerData && hddTrackerData?.BOQData?.map((item, key) => (
      <>
          <tr key={key}>
            <td style={styles.tableTd}>{item.PipeType || "-"}</td>
            <td colSpan={1} style={styles.tableTd}>{item.Quantity||"-"}</td>
            {/* <td style={styles.tableTd}>{item.unit || "-"}</td> */}
            <td colSpan={1} style={styles.tableTd}>{ parseFloat(item.length).toFixed(7)||"-"}</td>
            <td colSpan={1} style={styles.tableTd}>{"-"}</td>
            {/* <td style={styles.tableTd}>{item.unit || "-"}</td> */}
            <td colSpan={1} style={styles.tableTd}>{ "-"}</td>
            
          </tr>  

      </>
    ))}
  </>
) :null}

{projectType !== "Underground01" & Object.keys(detailedBOQProject || {}).length > 0  ? (
  <>
    {/* Table Headers for Underground01 */}
    <tr>
      <td style={styles.tableTh}>Item</td>
      <td colSpan={1} style={styles.tableTh}>Quantity</td>
      {/* <td  style={styles.tableTh}> Unit</td> */}
      <td colSpan={1} style={styles.tableTh}>Length</td>
    </tr>

    {/* Data Rows for Underground01 */}
    {detailedBOQProject && Object.keys(detailedBOQProject?.pipeDesc)?.map((pipeType, index) => (
      <>
        {detailedBOQProject.pipeDesc[pipeType]?.map((item, key) => (
          <tr key={key}>
            <td style={styles.tableTd}>{pipeType || "-"}</td>
                        <td colSpan={2} style={styles.tableTd}>{"-"}</td>
            {/* <td style={styles.tableTd}>{item.unit || "-"}</td> */}
            <td colSpan={1} style={styles.tableTd}>{"-"}</td>
            
          </tr>
        ))}
      </>
    ))}
  </>
) :null}
    </tbody>
  </table>
</div>
</div>
)
:null}
</div>

            </div>
          </div>

          {/* Right Section */}
          <div style={{ ...styles.rightSection, display: "flex", height: "100vh",width: !showPlannedTable && !showActualTable?"5000%":"50%" }}>
  {/* Left Map Section */}
  <div style={{ display: "flex", flexDirection: "column", gap: "20px", flex: 1 }}>


    {/* Map Section */}
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        flex: 1,
        height: "100%",
      }}
    >
      {/* {showPlannedMap && (
        <div
          style={{
            flex: showActualMap ? 1 : 2, // Full width if Actual Map is hidden
            borderRight: showActualMap ? "5px dotted #ccc" : "none",
            marginRight: showActualMap ? "1vw" : "0",
          }}
        >
          <h3>Planned</h3>
          <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={selectedPlanPoint?21:15}
              center={
                selectedPlanPoint?centerPlan:
                plannedDataLocation.length > 0
                  ? { lat: plannedDataLocation[0].lat, lng: plannedDataLocation[0].lng }
                  : defaultCenter
              }
            >
              {plannedDataLocation.map((project, index) => (
                <Marker
                  key={index}
                  style={{ width: selectedPlanPoint === project ? '300px' : '20px' }} // Adjust size based on selection
                  position={{ lat: project.lat, lng: project.lng }}
                  onClick={() => setSelectedMarker(project)}
                  icon={{
                    url:
                    project.type !== "WayPoint"
                    ? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 20 20">
    <rect width="16" height="12" fill="#ADD8E6" />
  </svg>
`)
                    :  "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
                        <rect x="10" y="4" width="4" height="16" fill="#ADD8E6" />
                        <rect x="4" y="10" width="16" height="4" fill="#ADD8E6" />
                      </svg>
                    `),
                        scaledSize: selectedPlanPoint === project
                        ? new window.google.maps.Size(60, 60)  // Increased size when selected
                        : new window.google.maps.Size(20, 20)  // Normal size
                  }}
                  label={{
                    text: 
                      project.type === "WayPoint"
                        ? `WayPoint-${project.count}`
                        : `Manhole-${project.count}`,
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                />
              ))}
              <Polyline path={path} options = {{
   strokeOpacity: 0,
   strokeWeight: 0,
   icons: [
    {
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE, // Use the circle for dots
        fillColor: "black",
        fillOpacity: 1,
        scale: 2,
      },
      offset: "0",
      repeat: "10px",
    },
     {
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE, // Use the circle for dots
        fillColor: "blue",
        fillOpacity: 1,
        scale: 2,
      },
      offset: "0",
      repeat: "10px",
    },
   ],
}} />
              {surveyData?(<>
              {surveyData?.map((project, index) => (
                
                <Marker
                  key={index}
                  
                  position={{ lat: project.lat, lng: project.lng }}
                  onClick={() => setSelectedMarker(project)  }
                  style={{maxWidth:"2vw",maxHeight:"2vh"}}
                  icon={{
                    url:
                      project.type === "comment"
                        ? comment
                        : null,
                        scaledSize: new window.google.maps.Size(50, 50), // Set the size here

                  }}
                  label={{
                    text: comment-`${project.count}`,
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                />
              ))}
            </>)
              :null}


              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: selectedMarker.lat,
                    lng: selectedMarker.lng,
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  {selectedMarker.type=="comment"?
                                   <div>
                                   {selectedMarker.comment?.map((item,key)=>(
                                   <>
                                   <h3>Comment- {key+1} {item.commentText||<>
                                   <img src={item.uri} style={{maxWidth:"30vw",maxHeight:"10vh",}}></img>
                                   </>}</h3>
                                   <p>Latitude: {selectedMarker.lat}</p>
                                   <p>Longitude: {selectedMarker.lng}</p>
                                </>
                                 ))}
                                 </div>
                  :
                  <div>
                    <h3>{selectedMarker.name}</h3>
                    <p>Latitude: {selectedMarker.lat}</p>
                    <p>Longitude: {selectedMarker.lng}</p>
                  </div>
}
                </InfoWindow>
              )}
            </GoogleMap>
        </div>
      )} */}
{/* {console.log(hddTrackerData)} */}
      {showActualMap && (
        <div
          style={{
            flex: showPlannedMap ? 1 : 2, // Full width if Planned Map is hidden
          }}
        >
          <h3>Map</h3>
          <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={selectedActualPoint?25: 15}
              center={selectedActualPoint?centerActual:
                plannedDataLocation?.length > 0
                  ? { lat:plannedDataLocation[0].lat, lng: plannedDataLocation[0]?.lng }
                  : defaultCenter
              }
            >
              {showPlannedMap?(<>
                            {plannedDataLocation.map((project, index) => (
                <Marker
                  key={index}
                  style={{ width: selectedActualPoint === project ? '300px' : '20px' }} // Adjust size based on selection
                  position={{ lat: project.lat, lng: project.lng }}
                  onClick={() => setSelectedMarker(project)}
                  icon={{
                    url:
                    project.type === "Manhole"
                    ? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 20 20">
                      <rect width="16" height="12" fill="#ADD8E6" />
                    </svg>
                  `)
                    : project.type==="WayPoint"? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
                        <rect x="10" y="4" width="4" height="16" fill="#ADD8E6" />
                        <rect x="4" y="10" width="16" height="4" fill="#ADD8E6" />
                      </svg>
                    `):require("../../../../assets/pole.png"),
                        scaledSize: selectedActualPoint === project
                        ? new window.google.maps.Size(60, 60)  // Increased size when selected
                        : new window.google.maps.Size(20, 20)  // Normal size
                  }}
                  label={{
                    text: 
                      project.type === "WayPoint"
                        ? `WayPoint-${project.count}`
                        : project.type==="Manhole"?`Manhole-${project.count}`:`Pole-${project.count}`,
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                />
              ))}
              <Polyline path={path} options = {{
   strokeOpacity: 0,
   strokeWeight: 0,
   icons: [
    {
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE, // Use the circle for dots
        fillColor: "bue",
        fillOpacity: 1,
        scale: 2,
      },
      offset: "0",
      repeat: "10px",
    },
     {
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE, // Use the circle for dots
        fillColor: "blue",
        fillOpacity: 1,
        scale: 2,
      },
      offset: "0",
      repeat: "10px",
    },
   ],
}} />
              {surveyData?(<>
              {surveyData?.map((project, index) => (
                
                <Marker
                  key={index}
                  
                  position={{ lat: project.lat, lng: project.lng }}
                  onClick={() => setSelectedMarker(project)  }
                  style={{maxWidth:"2vw",maxHeight:"2vh"}}
                  icon={{
                    url:
                      project.type === "comment"
                        ? comment
                        : null,
                        scaledSize: new window.google.maps.Size(50, 50), // Set the size here

                  }}
                  label={{
                    text: comment-`${project.count}`,
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                />
              ))}
            </>)
              :null}


              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: selectedMarker.lat,
                    lng: selectedMarker.lng,
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  {selectedMarker.type=="comment"?
                                   <div>
                                   {selectedMarker.comment?.map((item,key)=>(
                                   <>
                                   <h3>Comment- {key+1} {item.commentText||<>
                                   <img src={item.uri} style={{maxWidth:"30vw",maxHeight:"10vh",}}></img>
                                   </>}</h3>
                                   <p>Latitude: {selectedMarker.lat}</p>
                                   <p>Longitude: {selectedMarker.lng}</p>
                                </>
                                 ))}
                                 </div>
                  :
                  <div>
                    <h3>{selectedMarker.name}</h3>
                    <p>Latitude: {selectedMarker.lat}</p>
                    <p>Longitude: {selectedMarker.lng}</p>
                  </div>
}
                </InfoWindow>
              )}</>):null}
              {centerPlan?(<>
              {hddTrackerData?.trackingData?.map((item, index) =>{
              // console.log(centerActual)
              return (item?.map((project,index)=>(
                <Marker
                  key={index}
                  style={{ width: selectedActualPoint === project ? '300px' : '20px' }} // Adjust size based on selection
                  position={{ lat: project.lat, lng: project.lng }}
                  onClick={() => setSelectedMarker2(project)}
                  icon={{
                    url:
                      project.type === "Entry Pit"|| project.type==="Exit Pit"
                        ? "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 20 20">
        <rect width="16" height="12" fill="#ADD8E6" />
      </svg>
    `)
                        :  "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
                          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
                            <rect x="10" y="4" width="4" height="16" fill="#ADD8E6" />
                            <rect x="4" y="10" width="16" height="4" fill="#ADD8E6" />
                          </svg>
                        `),
                        scaledSize: selectedActualPoint === project
                        ? new window.google.maps.Size(60, 60)  // Increased size when selected
                        : new window.google.maps.Size(20, 20)  // Normal size
                      }}
                  label={{
                    text: 
                      project.type === "Entry Pit"
                        ? `Entry Pit-${project.count}`
                        :project.type==="Exit Pit"? 
                        `Exit Pit-${project.count}`:`Tracking Point-${project.count-1}`,
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                />
              )))})}
              
            {path2?.map((segment, segmentIndex) => (
  <Polyline
    key={segmentIndex}
    path={segment.map((point) => ({ lat: point.lat, lng: point.lng }))}
    options={{
      strokeColor: "blue",
      strokeOpacity: 0.8,
      strokeWeight: 4,
      geodesic: true,
    }}
  />
))}

              {/* {surveyData?(<>
              {surveyData?.map((project, index) => (
                
                <Marker
                  key={index}
                  
                  position={{ lat: project.lat, lng: project.lng }}
                  onClick={() => setSelectedMarker(project)}
                  style={{maxWidth:"2vw",maxHeight:"2vh"}}
                  icon={{
                    url:
                      project.type === "comment"
                        ? comment
                        : null,
                        scaledSize: new window.google.maps.Size(50, 50), // Set the size here

                  }}
                  label={{
                    text: comment-`${project.count}`,
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                />
              ))}
            </>)
              :null} */}


              {selectedMarker2 && (
                <InfoWindow
                  position={{
                    lat: selectedMarker2.lat,
                    lng: selectedMarker2.lng,
                  }}
                  onCloseClick={() => setSelectedMarker2(null)}
                >
                  {selectedMarker2.type=="comment"?
                                   <div>
                                   {selectedMarker2.comment?.map((item,key)=>(
                                   <>
                                   <h3>Comment- {key+1} {item.commentText||<>
                                   <img src={item.uri} style={{maxWidth:"30vw",maxHeight:"10vh",}}></img>
                                   </>}</h3>
                                   <p>Latitude: {selectedMarker2.lat}</p>
                                   <p>Longitude: {selectedMarker2.lng}</p>
                                </>
                                 ))}
                                 </div>
                  :
                  <div>
                    <h3>{selectedMarker2.name}</h3>
                    <p>Latitude: {selectedMarker2.lat}</p>
                    <p>Longitude: {selectedMarker2.lng}</p>
                  </div>
}
                </InfoWindow>
                
              )}</>):null}
            </GoogleMap>
        </div>
      )}
    </div>
  </div>
</div>


        </div>
     </> )}
    </>
  );
};

// Inline Styles
const styles = {
  container: {
    display: "flex",
    // justifyContent: "space-between",
    width: "100%",
    height: "90vh",
  },
  tableContainer: {
    overflow: "auto", /* Enables horizontal scrolling if the table overflows */
    maxWidth: "100%", /* Ensures the table does not extend beyond the container */
  },
  tablePlannedContainer: {
    overflow: "auto", /* Enables horizontal scrolling if the table overflows */
    maxWidth: "100%",
  },
  leftSection: {
    padding: "20px",
    backgroundColor: "#f4f4f4", /* Optional background color */
    overflowY: "auto",
  },
  rightSection: {
     /* Left half of the page */
    padding: "20px",
    overflowY: "auto",
  },
  projectDetails: {
    marginBottom: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    borderCollapse: 'collapse',  // Ensures borders are collapsed into one line
    border: '1px solid black'
  },
  tableTh: {
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
    border: '1px solid black', // Adds grid lines to headers
  },
  tableTd: {
    padding: "8px",
    textAlign: "left",
    border: '1px solid black', // Adds grid lines to cells
    backgroundColor: "#fff",
    minWidth: "2vw", // Set a minimum width for cells to ensure they don't collapse
    minHeight: "50px", // Set a minimum height for cells, even when empty
    height: "auto", // Ensures the height adjusts based on content but doesn't collapse
  },
};

export default InformationTable;
