import React, {useState, useEffect} from "react";
import axios from "../../../../axios_tteg";
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ResourceLocation from "./ResourceLocation";
import classes from "./tabPanel1.module.css";


const cookies = new Cookies()

const AssociatedAssets = (props)=>{
    const [associatedAssets,setAssociatedAssets] = useState(null);
    const [loading,setLoading] = useState(false);
    const [inputPIUtility,setInputPIUtility] = useState(null);
    const [inputOptionDomain,setInputOptionDomain] = useState([]);
    const [inputOptionMachine,setInputOptionMachine] = useState([]);
    const [inputOptionSubType,setInputOptionSubType] = useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [connectDetails,setConnectDetails] = React.useState(null);
    const [openResourceLocation,setOpenResourceLocation] = useState(false);
    const [countdownTimer, setCountdownTimer] = useState({
        hours:0,
        minutes:0,
        seconds:0
    });

    const innerWidth = window.innerWidth;

    useEffect(()=>{
        setLoading(true)
        axios.get('/registerPIUtility1')
        .then(response=>{
            setInputPIUtility(response.data);
            inputDomainFormat(response.data.domainBase);
            inputSubTypeFormat(response.data.subscriberType);
            
        })
        .catch(e=>console.log(e))

        axios.get('/getMachineType')
        .then(response=>{
            inputFormatMachine(response.data.machineType);
        })
        .catch(e=>console.log(e))
        getAssociateData();
    },[])

    const getAssociateData = ()=>{
        axios.post('/getAssociatedAssets',{
            companyID:cookies.get('userData').resourceID
        })
        .then(response=>{
            // console.log(response.data);
            setLoading(false);
            setAssociatedAssets(response.data.vendors);
        })
        .catch(e=>console.log(e))
    }

    const getCountdownTimer = (dateFuture)=>{
        dateFuture = new Date(dateFuture).setHours(23,59,59,999);
        
        let dateNow= new Date();
        var seconds = Math.floor((dateFuture - (dateNow))/1000);
        var minutes = Math.floor(seconds/60);
        var hours = Math.floor(minutes/60);
        var days = Math.floor(hours/24);

        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
        return({
            hours:hours,
            minutes:minutes,
            seconds:seconds
        })
    }

    const getDomainName = (domainID)=>{
        let tempDomainName = null;
        inputPIUtility.domain.map(item=>{

            if(item.domainID === domainID){
                tempDomainName = item.domainDescription.domainName
                // return (item.domainDescription.domainName);
            }
        });
        return tempDomainName
    }

    const getSubscriberType = (subscriberTypeID)=>{
        let tempSubscriberName = null;
        inputPIUtility.subscriberType.map(item=>{
            if(item.subscriberTypeID === subscriberTypeID){
                tempSubscriberName = item.subscriberTypeName
                // return (item.domainDescription.domainName);
            }
        });
        return tempSubscriberName
    }

    const getMachineType = (machineTypeID)=>{
        let tempMachineType = null;
        inputOptionMachine.map(item=>{
            if(item.value === machineTypeID)
                tempMachineType = item.label
        })
        return tempMachineType
    }

    const inputDomainFormat = (domainBase)=>{
        let format = []
        domainBase.map(item=>{
            format.push({
                label:item.domainBaseName,
                value:item.domainBaseID
            })
        })
        setInputOptionDomain(format)
    } 

    const inputSubTypeFormat = (subType)=>{
        let format = []
        subType.map(item=>{
            format.push({
                label:item.subscriberTypeName,
                value:item.subscriberTypeID
            })
        })
        setInputOptionSubType(format)
    } 

    const inputFormatMachine = (machineType)=>{
        let format = []
        machineType.map(item=>{
            format.push({
                label:item.machineTypeName,
                value:item.machineTypeID
            })
        })
        setInputOptionMachine(format)
    } 
    const requestForAssociation = ()=>{
        setLoading(true);
        
        axios.post("/updateResource",{vendorID:props.resourceID,resourceID:connectDetails.vendorResourceID})
        axios.post('/updateAssociation',{
            individualID:connectDetails.vendorResourceID,
            associationType :'freelance', // if needed we can created schema for associaton types
            companyID:null,
            timeStamp: new Date(),   
            machineID:connectDetails.machineID,
            humanResourceID:connectDetails.humanResourceID,
        })
        .then((response)=>{
            // console.log(response);
            setLoading(false);
            setConnectDetails(null);
            setOpenDialog(false);
            getAssociateData();
            alert('Thanks for using our resources, We hope you will come again')
        })
        .catch(e=>{
            console.log(e);
            setLoading(false);
        })

    }

    let container = null;
    if(loading || !associatedAssets || !inputPIUtility){
        container = (<CircularProgress/>)
    }

    else{
        if(associatedAssets && associatedAssets.length > 0){
            container = (
                <div className={classes.AssociatedAssestsContainer}>
                {associatedAssets.map(item=>{
                    if(!item.machineID && !item.humanResourceID){
                        return(
                            <div style = {{width:'100%'}}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            <div>
                                                <p style = {{textAlign:'right'}}>Valid Till (mm/dd/yyyy)- {new Date(new Date(item.endDate).setHours(23,59,59,999)).toLocaleString()}</p>
                                            </div>
                                            {innerWidth > 768 ? (
                                                <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                    <tr>
                                                        <th>Vendor Name</th>
                                                        <th>Domain</th>
                                                        <th>Subscriber Type</th>
                                                        <th>Type</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{item.VendorDetails[0].name}</td>
                                                        <td>{getDomainName(item.VendorDetails[0].domainID)}</td>
                                                        <td>{getSubscriberType(item.VendorDetails[0].subscriberTypeID)}</td>    
                                                        <td>{item.VendorDetails[0].resourceTypeID === 'I' ? 'Individual':'Company'}</td> 
                                                    </tr>
                                                </table>
                                            ):(
                                                <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                    <tr>
                                                        <td><b>Vendor Name</b></td>
                                                        <td>{item.VendorDetails[0].name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Domain</b></td>
                                                        <td>{getDomainName(item.VendorDetails[0].domainID)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Subscriber Type</b></td>
                                                        <td>{getSubscriberType(item.VendorDetails[0].subscriberTypeID)}</td> 
                                                    </tr>
                                                    <tr>
                                                        <td><b>Type</b></td>       
                                                        <td>{item.VendorDetails[0].resourceTypeID === 'I' ? 'Individual':'Company'}</td> 
                                                    </tr>
                                                </table>
                                            )}
                                            
                                        </Typography>
                                    </CardContent>
                                    <CardActions style={{justifyContent:'space-around'}}>
                                    <Button size="small" onClick={()=>{
                                        setOpenDialog(true);
                                        let tempConnectDetails = {};
                                        tempConnectDetails={
                                            vendorResourceID:item.individualID,
                                            machineID: null,
                                            humanResourceID:null
                                        }
                                        setConnectDetails(tempConnectDetails);
                                    }}>Remove</Button>
                                </CardActions>
                                </Card>
                            </div>
                        )
                    }
                    
                    else if(item.machineID){    
                        return(
                            <div style = {{width:'100%'}}>
                                <Card sx={{ width:'100%' }}>
                                    <CardContent>
                                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom 
                                            style =     {{display:'flex',justifyContent: 'space-between', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                <div>
                                                    Vandor Name - {item.VendorDetails[0].name}
                                                </div>
                                                <div>
                                                    Domain - {getDomainName(item.VendorDetails[0].domainID)}
                                                </div>
                                                <div>
                                                    Subscriber Type - {getSubscriberType(item.VendorDetails[0].subscriberTypeID)}
                                                </div>
                                                <div>
                                                    {item.VendorDetails[0].resourceTypeID === 'I' ? 'Individual':'Company'}
                                                </div>
                                        </Typography> */}
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                                            <div>
                                                <p style = {{textAlign:'right'}}>Valid Till (mm/dd/yyyy)- {new Date(new Date(item.endDate).setHours(23,59,59,999)).toLocaleString()}</p>
                                            </div>
                                            <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Model No.</th>
                                                    <th>Machine Capacity</th>
                                                    <th>Sector</th>
                                                    <th>OEM</th>
                                                    <th>Owner Name</th>
                                                    <th>Owner Mobile No.</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td>{getMachineType(item.machineDescription[0].machineTypeID)}</td>
                                                    <td>{item.machineDescription[0].modelNumber}</td>
                                                    <td>{item.machineDescription[0].machineCapacityInTonnes}</td>    
                                                    <td>{item.machineDescription[0].machineAvailabilitySectors}</td>   
                                                    <td>{item.machineDescription[0].OEM}</td>  
                                                    <td>{item.machineDescription[0].mResourceMachine[0].MachineOwner[0].name}</td>  
                                                    <td>{item.machineDescription[0].mResourceMachine[0].MachineOwner[0].mobile}</td>  
                                                    {/* <td><Button size="small" onClick={()=>{
                                                        setOpenDialog(true)
                                                        let tempConnectDetails = {};
                                                        tempConnectDetails={
                                                            vendorResourceID:item.resourceID,
                                                            machineID: item.machineDescription[0].machineID,
                                                            humanResourceID:null
                                                        }
                                                        setConnectDetails(tempConnectDetails);
                                                    }}>Associate</Button></td>                                                          */}
                                                </tr>
                                            </table>
                                        </Typography>
                                    </CardContent>
                                    <CardActions style={{justifyContent:'space-around'}}>
                                    <Button size="small" onClick={()=>{
                                        setOpenDialog(true);
                                        let tempConnectDetails = {};
                                        tempConnectDetails={
                                            vendorResourceID:item.individualAssociationSet[0].individualID,
                                            machineID: item.machineID,
                                            humanResourceID:null
                                        }
                                        setConnectDetails(tempConnectDetails);
                                    }}>Remove</Button>
                                </CardActions>
                                </Card>
                            </div>
                        )
                    }
                    else if(item.humanResourceID){
                        return(
                            <div style = {{width:'100%'}}>
                                <Card sx={{ width:'100%' }}>
                                    <CardContent>
                                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom 
                                            style =     {{display:'flex',justifyContent: 'space-between', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                <div>
                                                    Vandor Name - {item.VendorDetails[0].name}
                                                </div>
                                                <div>
                                                    Domain - {getDomainName(item.VendorDetails[0].domainID)}
                                                </div>
                                                <div>
                                                    Subscriber Type - {getSubscriberType(item.VendorDetails[0].subscriberTypeID)}
                                                </div>
                                                <div>
                                                    {item.VendorDetails[0].resourceTypeID === 'I' ? 'Individual':'Company'}
                                                </div>
                                        </Typography> */}
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                                            <div>
                                                <p style = {{textAlign:'right'}}>Valid Till (mm/dd/yyyy)- {new Date(new Date(item.endDate).setHours(23,59,59,999)).toLocaleString()}</p>
                                            </div>
                                            <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Domain</th>
                                                    <th>Skills</th>
                                                    <th>Experience</th>
                                                    <th>Tenure</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td>{item.humanResourceVendor[0].humanResourceInfo.name}</td>
                                                    <td>{getDomainName(item.humanResourceVendor[0].humanResourceInfo.domainTypeID)}</td>
                                                    <td>{item.humanResourceVendor[0].humanResourceInfo.skills}</td>    
                                                    <td>{item.humanResourceVendor[0].humanResourceInfo.experience}</td>   
                                                    <td>{item.humanResourceVendor[0].humanResourceInfo.tenure}</td>    
                                                    {/* <td><Button size="small" onClick={()=>{
                                                        setOpenDialog(true);
                                                        let tempConnectDetails = {};
                                                        tempConnectDetails={
                                                            vendorResourceID:item.resourceID,
                                                            machineID: null,
                                                            humanResourceID:innerItem.humanResourceID
                                                        }
                                                        setConnectDetails(tempConnectDetails);
                                                    }}>Associate</Button></td>  */}
                                                </tr>
                                            </table>
                                        </Typography>
                                    </CardContent>
                                    <CardActions style={{justifyContent:'space-around'}}>
                                        <Button size="small" onClick={()=>{
                                            setOpenDialog(true);
                                            let tempConnectDetails = {};
                                            tempConnectDetails={
                                                vendorResourceID:item.individualAssociationSet[0].individualID,
                                                machineID: null,
                                                humanResourceID:item.humanResourceID
                                            }
                                            setConnectDetails(tempConnectDetails);
                                        }}>Remove</Button>
                                    </CardActions>
                                </Card>
                            </div>
                        )
                    }
                })}
                </div>
            )
        }
        else{
            container = (
                <div>
                    <p>No assets found !!</p>
                </div>
            )
        }
    }

    // <div style={{display: 'grid',gridTemplateColumns: 'auto',padding: 10,justifyItems:'center', margin:20}}>
    //             {filterData.map(item=>{
    //                 return(
    //                     <div style = {{margin:10, width:'100%'}}>
    //                         <Card sx={{ minWidth: 275 }}>
    //                             <CardContent>
    //                                 <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style = {{display:'flex',justifyContent: 'space-between', background: 'aliceblue',padding: 10,borderRadius: 5}}>
    //                                     <div>
    //                                         Vandor Name - {item.name}
    //                                     </div>
    //                                     <div>
    //                                         Domain - {getDomainName(item.domainID)}
    //                                     </div>
    //                                     <div>
    //                                         Subscriber Type - {getSubscriberType(item.subscriberTypeID)}
    //                                     </div>
    //                                     <div>
    //                                         {item.resourceTypeID === 'I' ? 'Individual':'Company'}
    //                                     </div>
    //                                 </Typography>
    //                                 {(item.machineDescription.length > 0) ? (
    //                                     <div>
    //                                         <p style={{textAlign:'left'}}>Machine Details :</p>
    //                                         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
    //                                             <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
    //                                                 <tr>
    //                                                     <th>Type</th>
    //                                                     <th>Model No.</th>
    //                                                     <th>Machine Capacity</th>
    //                                                     <th>Sector</th>
    //                                                     <th>OEM</th>
    //                                                     <th></th>
    //                                                 </tr>
    //                                                 {item.machineDescription.map(innerItem=>{
    //                                                     return( 
    //                                                         <tr>
    //                                                             <td>{getMachineType(innerItem.machineTypeID)}</td>
    //                                                             <td>{innerItem.modelNumber}</td>
    //                                                             <td>{innerItem.machineCapacityInTonnes}</td>    
    //                                                             <td>{innerItem.machineAvailabilitySectors}</td>   
    //                                                             <td>{innerItem.OEM}</td>    
    //                                                             <td><Button size="small" onClick={()=>{
    //                                                                 setOpenDialog(true)
    //                                                                 let tempConnectDetails = {};
    //                                                                 tempConnectDetails={
    //                                                                     vendorResourceID:item.resourceID,
    //                                                                     machineID: innerItem.machineID,
    //                                                                     humanResourceID:null
    //                                                                 }
    //                                                                 setConnectDetails(tempConnectDetails);
    //                                                             }}>Associate</Button></td>                                                         
    //                                                         </tr>
    //                                                     )
    //                                                 })}
    //                                             </table>
    //                                         </Typography>
    //                                     </div>
                                        
                                        
    //                                 )
                                        
                                        
    //                                  :null}
    //                                 {(item.humanResourceVendor && item.humanResourceVendor.length > 0) ? (
    //                                     <div>
    //                                         <p style={{textAlign:'left'}}>Human Resource Details :</p>
    //                                         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
    //                                             <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
    //                                                 <tr>
    //                                                     <th>Name</th>
    //                                                     <th>Domain</th>
    //                                                     <th>Skills</th>
    //                                                     <th>Experience</th>
    //                                                     <th>Tenure</th>
    //                                                     <th></th>
    //                                                 </tr>
    //                                                 {item.humanResourceVendor.map(innerItem=>{
    //                                                     return( 
    //                                                         <tr>
    //                                                             <td>{item.humanResourceVendor[0].name}</td>
    //                                                             <td>{getDomainName(innerItem.humanResourceInfo.domainTypeID)}</td>
    //                                                             <td>{innerItem.humanResourceInfo.skills}</td>    
    //                                                             <td>{innerItem.humanResourceInfo.experience}</td>   
    //                                                             <td>{innerItem.humanResourceInfo.tenure}</td>    
    //                                                             <td><Button size="small" onClick={()=>{
    //                                                                 setOpenDialog(true);
    //                                                                 let tempConnectDetails = {};
    //                                                                 tempConnectDetails={
    //                                                                     vendorResourceID:item.resourceID,
    //                                                                     machineID: null,
    //                                                                     humanResourceID:innerItem.humanResourceID
    //                                                                 }
    //                                                                 setConnectDetails(tempConnectDetails);
    //                                                             }}>Associate</Button></td>                                                         </tr>
    //                                                     )
    //                                                 })}
    //                                             </table>
    //                                         </Typography>
    //                                     </div>
                                        
                                        
    //                                 )
                                        
                                        
    //                                  :null}
    //                                 {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
    //                                 Domain - {getDomainName(item.domainID)}
    //                                 </Typography>
    //                                 <Typography sx={{ mb: 1.5 }} color="text.secondary">
    //                                 Subscriber Type - {getSubscriberType(item.subscriberTypeID)}
    //                                 </Typography> */}
                                    
    //                             </CardContent>
    //                             <CardActions style={{justifyContent:'space-around'}}>
    //                                 <Button size="small" onClick={()=>{
    //                                     setOpenDialog(true);
    //                                     let tempConnectDetails = {};
    //                                     tempConnectDetails={
    //                                         vendorResourceID:item.resourceID,
    //                                         machineID: null,
    //                                         humanResourceID:null
    //                                     }
    //                                     setConnectDetails(tempConnectDetails);
    //                                 }}>Associate</Button>
    //                             </CardActions>
    //                         </Card>
    //                     </div>
    //                 )
    //             })}
    //         </div>

    return(
        <div>
            {openResourceLocation ? (
                <ResourceLocation associatedAssets = {associatedAssets} inputPIUtility = {inputPIUtility} getData = {()=>setOpenResourceLocation(false)}/>
            ):(
                <div>
                    <div >
                        <LocationOnIcon 
                            style = {{float:'right', color:'teal',fontSize:24, cursor:'pointer' }}
                            onClick = {()=>setOpenResourceLocation(true)}
                        />
                    </div>
                    {container}
                    <div>
                        <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
                            <DialogTitle>Associate</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Thanks for using our resources, We hope you will come again
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>setOpenDialog(false)}>Cancel</Button>
                                <Button onClick={requestForAssociation}>Submit</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AssociatedAssets