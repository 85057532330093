import React,{useState, useEffect} from 'react';
import axios from '../../axios_tteg';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import classes from './Projectmanagement.module.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Planning from './Planning';
import SubProject from './SubProject';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ProjectAssociation from './ProjectAssociation';
import { useNavigate } from "react-router-dom";
import Tracker from './Tracker';
import Opentrench from './Opentrench';
import Arial from './Arial';

const ProjectManagementSystem = (props)=>{
    let navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [mapData,setMapData] = useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(()=>{
        getMapData();
    },[])

    // const getDetailedBOQ = ()=>{
    //     let pipeDesc = {};
    //     let countDesc = {};
    //     let MHContainer = [];
    //     let PoleContainer = [];
    //     // layers[0].map((layer,layerIndex)=>{
    //     layers[0].map((route,routeIndex)=>{
    //         route.markers.map((marker,markerIndex)=>{
    //         if(marker.type === 'Manhole'){
    //             if(countDesc[marker.type]){
    //             countDesc[marker.type] = countDesc[marker.type]+1;
    //             }
    //             else{
    //             countDesc[marker.type] = 1;
    //             }
    //             MHContainer.push(marker);
    //         }
    //         else if(marker.type === 'Pole'){
    //             if(countDesc[marker.type]){
    //             countDesc[marker.type] = countDesc[marker.type]+1;
    //             }
    //             else{
    //             countDesc[marker.type] = 1;
    //             }
    //             PoleContainer.push(marker);
    //         }
    //         })
    //     })
    //     if(MHContainer.length > 0){
    //         for(let i = 0;i < MHContainer.length; i = i+1){
    //         if(i+1 < MHContainer.length){
    //             MHContainer[i].pipeContainers.C.map((innerItem,innerIndex)=>{
    //             if(pipeDesc[innerItem.PipeType]){
    //                 pipeDesc[innerItem.PipeType].push({
    //                 quantity:innerItem.Quantity,
    //                 length : distBtwPoints(
    //                     {
    //                     lat:MHContainer[i].lat,
    //                     lng:MHContainer[i].lng
    //                     },{
    //                     lat:MHContainer[i+1].lat,
    //                     lng:MHContainer[i+1].lng
    //                     })
    //                 }) 
    //             }
    //             else{
    //                 pipeDesc[innerItem.PipeType] = [{
    //                 quantity:innerItem.Quantity,
    //                 length : distBtwPoints(
    //                     {
    //                     lat:MHContainer[i].lat,
    //                     lng:MHContainer[i].lng
    //                     },{
    //                     lat:MHContainer[i+1].lat,
    //                     lng:MHContainer[i+1].lng
    //                     }
    //                 )
    //                 }]
    //             }
                
    //             })
    //         }
    //         }
    //     }
          
    //     return({
    //         pipeDesc:pipeDesc,
    //         countDesc:countDesc
    //     })
    // }
    const getMapData = ()=>{
        setLoading(true)
        axios.get('/getMapAuditFinal',{
            params:{
              caseID:props.caseDetails.caseID
            }
          })
          .then(response=>{
            setLoading(false)
            setMapData(response.data)
          })
          .catch(e=>console.log(e))
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
        
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    let container = null;
    if(loading){
        container = (<CircularProgress/>)
    }
    else if(mapData){
        container = (
            <div>
                <div style = {{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <ArrowBackIcon 
                        style={{margin:10, cursor:'pointer'}}
                        onClick = {()=>props.getData('back')}
                    />
                    <h3 style={{textAlign:'center', margin:5, cursor:'pointer'}}>Project Management System</h3>
                    <CloseIcon 
                        style={{margin:10, cursor:'pointer'}}
                        onClick = {()=>navigate('/')}
                    />
                </div>
                <Card sx={{ minWidth: 275, margin:'5px 0px' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className={classes.AssignmentHeader}>
                            <div>
                                Title - {props.caseDetails.caseMaster[0].title}
                            </div>
                            <div>
                                Case ID - {props.caseDetails.caseID}
                            </div>
                            <div>
                                Company - {props.caseDetails.caseMaster[0].companyDetails.name}
                            </div>
                            <div>
                                Domain - {props.caseDetails.caseMaster[0].domainDetails.domainDescription.domainName}
                            </div>
                        </Typography>
                        
                    </CardContent>
                </Card>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Home" {...a11yProps(0)} />
                        <Tab label="Sub-Projects" {...a11yProps(1)} />
                        <Tab label='Associate Resource' {...a11yProps(2)}/>
                        <Tab label='HDD' {...a11yProps(3)}/>
                        <Tab label='Open Trench' {...a11yProps(4)}/>
                        <Tab label='Arial' {...a11yProps(5)}/>
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Planning
                            caseDetails={props.caseDetails}
                            mapData = {mapData}
                            getData = {(res)=>{
                                if (res === 1)
                                    setValue(1)
                                else if (res === 2)
                                    setValue(2)
                            }}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <SubProject
                            caseDetails={props.caseDetails}
                            mapData = {mapData}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <ProjectAssociation
                            caseDetails={props.caseDetails}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <Tracker
                            caseDetails={props.caseDetails}
                            mapData={mapData}
                        />
                    </CustomTabPanel>
                </Box>
                <CustomTabPanel value={value} index={4}>
                        <Opentrench
                            caseDetails={props.caseDetails}
                            mapData={mapData}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <Arial
                            caseDetails={props.caseDetails}
                            mapData={mapData}
                        />
                    </CustomTabPanel>
            </div>
        )
    }
    
    return (
        <div>
            {container}
        </div>
    )
}

export default ProjectManagementSystem